import React, { Component } from "react";
// import IconFont from "../components/common/IconFont";
import WrapperButton from "../components/common/WrapperButton";
import GlobalBreadcrumb from "../components/common/GlobalBreadcrumb";
import MainSectionDisplay from "../components/common/MainSectionDisplay";
import GridDisplay from "../components/common/GridDisplay";
import { ScrollTo } from "../script/Utils";
import { NewsData } from "../script/MockDatas";
// import { 
    // Button,
    // Icon,
    // Menu,
    // Dropdown
// } from "antd";

const data = {
  title: "新闻中心",
  desc: "游龙大事记、技术更新公告等，来新闻中心，探索更多游龙新动态。",
  items: NewsData,
  btn: { text: "加载更多" }
};
export default class NewsCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownValue1: "按年份",
      dropdownValue2: "按话题",
      pageSize: 6
    };
  }

  componentDidMount() {
    ScrollTo();
  }

  renderTabBar = (props, DefaultTabBar) => {
    return (
      <div
        className="custom-tab-center"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DefaultTabBar style={{ width: "100%" }} {...props} />
      </div>
    );
  };

  handleMenuClick = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { 
        // dropdownValue1, 
        // dropdownValue2, 
        pageSize 
    } = this.state;
    // const menu = (
    //   <Menu onClick={e => this.handleMenuClick("dropdownValue1", e.key)}>
    //     <Menu.Item key="按年份">按年份</Menu.Item>
    //     <Menu.Item key="按其他">按其他</Menu.Item>
    //   </Menu>
    // );
    // const menu2 = (
    //   <Menu onClick={e => this.handleMenuClick("dropdownValue2", e.key)}>
    //     <Menu.Item key="按话题">按话题</Menu.Item>
    //     <Menu.Item key="按其他">按其他</Menu.Item>
    //   </Menu>
    // );
    return (
      <div className="aboutView">
        <div className="breadcrumb-wrapper">
          <GlobalBreadcrumb {...this.props} />
        </div>
        <div className="light-content" style={{ paddingBottom: "45px" }}>
          <MainSectionDisplay
            title={data.title}
            desc={data.desc}
            titleStyle={{
              marginTop: "62px",
              textAlign: "left",
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            descStyle={{
              marginTop: "29px",
              marginBottom: "5px",
              textAlign: "left",
              width: "85%"
            }}
          />
        </div>
        {/* <div className="filter-bar">
                    <div>
                        <span className="title">筛选列表</span>
                        <Dropdown overlay={menu}>
                            <Button>{dropdownValue1} <IconFont type="sv-LC_icon_down_fill" /></Button>
                        </Dropdown>
                        <Dropdown overlay={menu2}>
                            <Button>{dropdownValue2} <IconFont type="sv-LC_icon_down_fill" /></Button>
                        </Dropdown>
                    </div>
                    <Button>搜索 <Icon type="search" /></Button>
                </div> */}
        <div className="filter-list-wrapper light-content">
          <div className="filter-result">
            {pageSize} of {data.items.length} 条结果
          </div>
          <div className="filter-list">
            <GridDisplay
              items={data.items.filter((d, index) => index < pageSize)}
              imgHeight="276px"
              shadow
              gutter={{ xs: 0, sm: 19 }}
              spanRules={{ xs: 24, sm: 8 }}
            />
          </div>
          {pageSize !== data.items.length ? (
            <div style={{ textAlign: "center", paddingBottom: "45px" }}>
              <WrapperButton
                className="linearBorderBtn"
                style={{ width: "221px" }}
                onClick={() => this.setState({ pageSize: data.items.length })}
              >
                {data.btn.text}
              </WrapperButton>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
