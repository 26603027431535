import React, { Component } from 'react';
import IconFont from '../components/common/IconFont';
import GlobalBreadcrumb from '../components/common/GlobalBreadcrumb';
import WrapperButton from '../components/common/WrapperButton';
import { ScrollTo } from '../script/Utils';
import { SolutionsData } from '../script/MockDatas';
import { Row, Col } from 'antd';

export default class SolutionsSubView extends Component {

    componentDidMount() {
        ScrollTo();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.title !== prevProps.match.params.title) {
            ScrollTo();
        }
    }

    render() {
        const curentItem = SolutionsData.find(d => d.section1.title === this.props.match.params.title);
        return (
            <div className="solutionsSubView productView productItsm">
                <div className="breadcrumb-wrapper"><GlobalBreadcrumb {...this.props} /></div>
                <div className="product-itsm-section1">
                    <div className="product-itsm-section1-img-wrapper" style={{backgroundImage: `url(${require('../images/' + curentItem.section1.img)})`}}></div>
                    <div className="product-itsm-section1-content-wrapper">
                        <div className="content-header">
                            <div className="content-header-title">{curentItem.section1.title}</div>
                            <div className="content-header-second-title">{curentItem.section1.desc}</div>
                            <div className="content-header-second-title">{curentItem.section1.desc2}</div>
                        </div>
                        <div className="content-content">{curentItem.section1.content}</div>
                        <div className="content-btns">
                            {curentItem.section1.btns.map((item, index) => (
                                <WrapperButton className="linearBorderBtn" key={index} style={{width: '221px'}} btn={item}>{item.text}</WrapperButton>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="normal-media-padding solutions-sub-section2">
                      {curentItem.section2.map((item, index) => (
                          <div key={index}>
                              <div className="solutions-sub-title">{item.title}</div>
                              {item.contents.map((content, cIndex) => (
                                  <div className="solutions-sub-content" key={cIndex}>{content}</div>
                              ))}
                          </div>
                      ))}
                </div>
                <div className="normal-media-padding">
                    <div className="solutions-sub-title">{curentItem.section3.title}</div>
                    <div className="solutions-sub-content">{curentItem.section3.desc}</div>
                    <Row gutter={{sm: 0, md: 19}} type="flex">
                        {curentItem.section3.items.map((item, index) => (
                            <Col sm={24} md={8} style={{marginBottom: '19px'}} key={index}>
                                <div className="solutions-sub-section3-block">
                                    <div className="solutions-sub-section3-block-title">{item.title}</div>
                                    <div className="solutions-sub-section3-block-content">{item.content}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className="normal-media-padding solutions-sub-title" style={{marginTop: '10px', marginBottom: '30px'}}>{curentItem.section4.title}</div>
                <div className="product-itsm-section4">
                    <Row className="se-block-wrapper" gutter={{sm: 0, md: 19}}>
                        {curentItem.section4.items.map((se, seIndex) => (
                            <Col sm={24} md={8}  key={seIndex} style={{marginBottom: '19px'}}>
                                <div className="se-item">
                                    {se.icon ? <IconFont type={se.icon} />:null}
                                    <div className="se-item-content">{se.content}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div style={{textAlign: 'center', marginTop: '26px'}}>
                        {curentItem.section4.btns.map((btn, bIndex) => {
                            return (
                                <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
