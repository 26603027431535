import React, { Component } from 'react';
import WrapperButton from './WrapperButton';

export default class MainSectionDisplay extends Component {
    render() {
        const { img, title, desc, btns, height, titleStyle, descStyle, btnType, style } = this.props;
        let _style = {}, desc_span_style = {}, _img = null;
        if (height) {
            _style.height = isNaN(height) ? height : `${height}px`;
        }
        if (img) {
            _img = (
                <div className="img-wrapper" style={_style}>
                    <img src={require(`../../images/${img}`)} alt=""/>
                </div>
            );
            desc_span_style.background = 'rgba(24, 144, 255, 0.75)';
            desc_span_style.padding = '4px 16px 7px 16px';
        }
        return (
            <div className="mainSection" style={style||{}}>
                {_img}
                <div className={"mainSectionContent" + (img ? " hasImg": "")}>
                    <div>
                        <div className="section-title" style={titleStyle || {}}>{title}</div>
                        <div className="section-desc" style={descStyle || {}}><span style={desc_span_style}>{desc}</span></div>
                        <div>
                            {btns && btns.map((btn, index) => {
                                return btnType === 'transparent' ? 
                                    <WrapperButton key={index} className="hover-move-top" style={{width: '221px', margin: '0 15px'}} ghost btn={btn}>{btn.text}</WrapperButton>
                                    : <WrapperButton key={index} className="whiteBtn hover-move-top" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
