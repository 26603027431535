import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

class WrapperButton extends Component {

    onClick = (e) => {
        const { btn, onClick } = this.props;
        if (onClick && typeof onClick === 'function') {
            onClick(e);
        }
        if (!btn) return;
        if (btn.target === '_blank') {
            window.open(btn.to, '_blank');
        } else if (btn.to !== undefined) {
            this.props.history.push(btn.to);
        }
    }

    render() {
        const { children, className, staticContext, btn, ...otherProps } = this.props;
        let _className = "wrapper-button";
        if (className && className.indexOf('linearBorderBtn') > -1) {
            const { style, ..._otherProps } = otherProps;
            let height = 54, width = undefined, _style = {}, _linearStyle = {};
            style && Object.keys(style).forEach(key => {
                if (key === 'height') {
                    height = parseInt(style[key].replace('px', ''));
                }
                if (key === 'width') {
                    width = parseInt(style[key].replace('px', ''));
                }
                if (key.indexOf('margin') > -1) {
                    _linearStyle[key] = style[key];
                    delete style[key];
                }
            });
            _style.height = (height - 2) + 'px';
            if (width) {
                _style.width = (width - 2) + 'px';
            }
            return (
                <div className="linearBorderBtn" style={_linearStyle}>
                    <Button className={_className} {..._otherProps} style={_style} onClick={this.onClick}>{children}</Button>
                </div>
            )
        } else {
            if (className) {
                _className += " " + className;
            }
            return (
                <Button className={_className} {...otherProps} onClick={this.onClick}>{children}</Button>
            )
        }
    }
}

export default withRouter(WrapperButton)
