import React, { Component } from 'react';
import GlobalBreadcrumb from '../components/common/GlobalBreadcrumb';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import GridDisplay from '../components/common/GridDisplay';
import { ScrollTo } from '../script/Utils';

const data = {
    section1: {
        title: '解决方案',
        desc: '全面、深度监测，智能运维管理，保障企业运营最大化效益',
        img: '解决方案-Banner.jpg',
        btn: {
            text: '获取解决方案',
            to: '/GetSolutions'
        }
    },
    section2: {
        title: '多种技术方案',
        desc: 'SITEVIEW ITOSS应用范围广，适应多种情境IT运维及组网',
        items: [
            { img: '解决方案-企业运维.jpg', title: '企业运维', link: { label: '查看方案', to: { pathname: '/Solutions/企业运维' } } },
            { img: '解决方案-IT运营商.jpg', title: 'IT运营商', link: { label: '查看方案', to: { pathname: '/Solutions/IT运营商' } } },
            { img: '解决方案-企业业务协同.jpg', title: '企业业务协同', link: { label: '查看方案', to: { pathname: '/Solutions/企业业务协同' } } },
            { img: '解决方案-现场服务.jpg', title: '现场服务', link: { label: '查看方案', to: { pathname: '/Solutions/现场服务' } } },
            { img: '解决方案-客户服务.jpg', title: '客户服务', link: { label: '查看方案', to: { pathname: '/Solutions/客户服务' } } },
            { img: '首页-ITSM.jpg', title: '连锁IT服务', link: { label: '查看方案', to: { pathname: '/Solutions/连锁IT服务' } } },
            { img: '解决方案-数据中心运维.jpg', title: '数据中心运维', link: { label: '查看方案', to: { pathname: '/Solutions/数据中心运维' } } },
            { img: '解决方案-ITSS工具.jpg', title: '一体化运维', link: { label: '查看方案', to: { pathname: '/Solutions/一体化运维' } } },
        ]
    },
    section3: {
        title: '更多垂直泛行业',
        desc: 'SITEVIEW ITOSS应用范围广，适应多种情境IT运维及组网',
        items: [
            { img: '解决方案-智能硬件.jpg', title: '智能硬件' },
            { img: '解决方案-电信运营商.jpg', title: '电信运营商' },
            { img: '解决方案-健康医疗.jpg', title: '健康医疗' },
            { img: '解决方案-房地产.jpg', title: '房地产' },
            { img: '解决方案-旅游出行.jpg', title: '旅游出行' },
            { img: '解决方案-百货零售.jpg', title: '百货零售' },
            { img: '解决方案-物流仓储.jpg', title: '物流仓储' },
            { img: '解决方案-计算机软硬件.jpg', title: '计算机软硬件' },
        ]
    }
};

export default class Solutions extends Component {

    componentDidMount() {
        ScrollTo();
    }

    render() {
        return (
            <div className="solutionsView">
                <div className="breadcrumb-wrapper"><GlobalBreadcrumb {...this.props} /></div>
                <MainSectionDisplay
                    img={data.section1.img}
                    title={data.section1.title}
                    desc={data.section1.desc}
                    btns={[data.section1.btn]}
                    descStyle={{margin: '35px auto 50px'}}
                />
                <MainSectionDisplay
                    title={data.section2.title}
                    desc={data.section2.desc}
                    titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                    descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px'}}
                />
                <div style={{padding: '0 45px', marginBottom: '-45px'}}>
                    <GridDisplay items={data.section2.items} imgHeight="210px" />
                </div>
                <MainSectionDisplay
                    title={data.section3.title}
                    desc={data.section3.desc}
                    titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                    descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px'}}
                />
                <div style={{padding: '0 45px', marginBottom: '-45px'}}>
                    <GridDisplay items={data.section3.items} imgHeight="210px" />
                </div>
            </div>
        )
    }
}
