import React, { Component } from 'react';
import WrapperButton from '../components/common/WrapperButton';
import GlobalBreadcrumb from '../components/common/GlobalBreadcrumb';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import GlobalMarkdown from '../markdown/GlobalMarkdown';
import { ScrollTo } from '../script/Utils';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const data = {
    title: '在线体验指南',
    img: 'alesia-kazantceva-XLm6-fPwK5Q-unsplash.jpg',
    desc: '试用我们的Demo',
    
    tabs: [
        { 
            title: "SITEVIEW ECC IT基础设施监控",
            tabs: [
                { title: 'ECC 在线体验指南', content: `在线体验指南(<a href="https://demo.siteview.com" target="_blank">DEMO</a>)` },
                { title: '添加Windows服务器监测实例', content: '添加Windows服务器监测实例' },
                { title: '添加Linux服务器监测实例', content: '添加Linux服务器监测实例' },
                { title: '添加数据库监测实例', content: '添加数据库监测实例' },
                { title: '添加网络设备监测实例', content: '添加网络设备监测实例' }
            ],
            btns: [
                { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
                { text: '免费下载试用', to: '/Download' },
            ],
        },
        { 
            title: "SITEVIEW IT服务管理",
            tabs: [
                { title: 'ITSM 在线体验指南', content: `在线体验指南(<a href="https://eccqa.weadmin.com" target="_blank">DEMO</a>)` }
            ],
            btns: [
                { text: '免费云试用版', to: "https://eccqa.weadmin.com", target: '_blank' },
                { text: '免费下载试用', to: '/Download' },
            ],
        },
    ]
};

export default class OnlineExperience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab0: '0',
            tab1: '0',
        };
    }

    componentDidMount() {
        ScrollTo();
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center" style={{display: 'flex', justifyContent: 'center'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center tab-left" style={{background: '#fff'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    render() {
        return (
            <div className="onlineExperienceView">
                <div className="breadcrumb-wrapper"><GlobalBreadcrumb {...this.props} /></div>
                <div className="light-content" style={{paddingBottom: '45px'}}>
                    <MainSectionDisplay
                        title={data.title}
                        img={data.img}
                        height="300px"
                        desc={data.desc}
                        titleStyle={{marginTop: '62px', textAlign: 'left', width: '85%', marginLeft: 'auto', marginRight: 'auto'}}
                        descStyle={{marginTop: '29px', marginBottom: '5px', textAlign: 'left', width: '85%'}}
                    />
                </div>
                <Tabs renderTabBar={this.renderTabBar}>
                    {data.tabs.map((ptab, ptIndex) => (
                        <TabPane key={ptIndex} tab={ptab.title}>
                            <div>
                                <div className="verticalTab-wrapper">
                                    <Tabs activeKey={this.state['tab' + ptIndex]} tabPosition="left" onChange={tab => this.setState({['tab' + ptIndex]: tab})}>
                                        {ptab.tabs.map((item, index) => (
                                            <TabPane tab={<span className="tab-title">{item.title}</span>} key={index}>
                                                <div className="content-title"><span dangerouslySetInnerHTML={{__html: item.content}}></span></div>
                                                <div className="content-container">
                                                    <GlobalMarkdown 
                                                        markdown={require(`../markdown/Online/tab-${ptIndex}-${index}/index.md`)} 
                                                        imgPath={`./Online/tab-${ptIndex}-${index}/`} 
                                                    />
                                                    {index === 0 ? (
                                                        <ul>
                                                            {ptab.tabs.map((stab, stIndex) => (
                                                                stIndex === 0 ? null : (
                                                                    <li key={stIndex}><a onClick={()=>this.setState({['tab' + ptIndex]: stIndex + ''})}>{stab.title}</a></li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    ):null}
                                                </div>
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                                <div style={{textAlign: 'center', marginTop: '45px'}}>
                                    {ptab.btns.map((btn, index) => {
                                        return (
                                            <WrapperButton key={index} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                        )
                                    })}
                                </div>
                            </div>
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        )
    }
}
