import React from "react";
import { PropTypes } from "prop-types";

const OPTIONS = {
  fontSizeMin: 22, // 字体尺寸最小值
  fontSizeMax: 26, // 字体尺寸最大值
  colors: ["#117cb3", "#f47b06", "#202890", "#db1821", "#b812c2"], // 字可能的颜色
  fonts: ["Times New Roman", "Georgia", "Serif", "sans-serif", "arial", "tahoma", "Hiragino Sans GB"], // 可能的字体
  lines: 18, // 生成多少根线
  lineColors: ["#7199e1", "#383838", "#ec856d", "#008888", "#cccee2", "#8e6710", "#130d01", "#000002"], // 线可能的颜色
  lineHeightMin: 1, // 线的粗细最小值
  lineHeightMax: 1, // 线的粗细最大值
  lineWidthMin: 20, // 线的长度最小值
  lineWidthMax: 60 // 线的长度最大值
};

const CODES = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
  "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
  "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
];

export default class Captcha extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      result: [],
      style: {
        height: props.height + "px",
        width: props.width + "px"
      }
    };
  }

  componentDidMount() {
    this.createCodes();
  }

  random(n, m) {
    const c = m - n + 1;
    const num = Math.random() * c + n;
    return Math.floor(num);
  }

  createCodes() {
    const { length, codes } = this.props;
    let result = [], lines = [];
    for (let i = 0; i < length; i++) {
      result.push({
        code: codes[this.random(0, codes.length - 1)],
        style: this.codeStyle(i)
      });
    }
    for (let i = 0; i < OPTIONS.lines; i++) {
      lines.push({ style: this.createLines() });
    }

    this.setState({ result, lines }, () => {
      this.onChange();
    });
  }

  codeStyle(i) {
    const uW = this.props.width / this.props.length; // 每个字符占的宽度
    return {
      fontSize: `${this.random(OPTIONS.fontSizeMin, OPTIONS.fontSizeMax)}px`,
      color: `${OPTIONS.colors[this.random(0, OPTIONS.colors.length - 1)]}`,
      position: "absolute",
      left: `${this.random(uW * i, uW * i + uW - uW / 2)}px`,
      top: "50%",
      transform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      OTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      MsTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      MozTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      WebkitTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      fontFamily: `${OPTIONS.fonts[this.random(0, OPTIONS.fonts.length - 1)]}`,
      fontWeight: "bold",
      zIndex: "2"
    };
  }

  createLines() {
    return {
      position: "absolute",
      opacity: `${this.random(3, 8) / 10}`,
      width: `${this.random(OPTIONS.lineWidthMin, OPTIONS.lineWidthMax)}px`,
      height: `${this.random(OPTIONS.lineHeightMin, OPTIONS.lineHeightMax)}px`,
      background: `${
        OPTIONS.lineColors[this.random(0, OPTIONS.lineColors.length - 1)]
      }`,
      left: `${this.random(-OPTIONS.lineWidthMin / 2, this.props.width)}px`,
      top: `${this.random(0, this.props.height)}px`,
      transform: `rotate(${this.random(-30, 30)}deg)`,
      OTransform: `rotate(${this.random(-30, 30)}deg)`,
      MsTransform: `rotate(${this.random(-30, 30)}deg)`,
      MozTransform: `rotate(${this.random(-30, 30)}deg)`,
      WebkitTransform: `rotate(${this.random(-30, 30)}deg)`,
      fontFamily: `${OPTIONS.fonts[this.random(0, OPTIONS.fonts.length - 1)]}`,
      fontWeight: `${this.random(400, 900)}`
    };
  }

  onChange() {
    const { result } = this.state;
    let code = "";
    result.forEach(item => {
      code += item.code;
    });
    this.props.onChange(code);
  }

  render() {
    const { result, lines } = this.state;
    const style = Object.assign(
      {
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#fff",
        cursor: "pointer",
        verticalAlign: "middle",
        userSelect: "none"
      },
      this.state.style,
      this.props.style
    );
    return (
      <div style={style} onClick={() => this.createCodes()}>
        {result.map((item, index) => {
          return (
            <span key={index} style={item.style}>
              {item.code}
            </span>
          );
        })}
        {lines &&
          lines.map((item, index) => {
            return <div key={index} style={item.style} />;
          })}
      </div>
    );
  }
}

Captcha.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
  length: PropTypes.number,
  onChange: PropTypes.func,
  codes: PropTypes.array
};

Captcha.defaultProps = {
  height: "40",
  width: "150",
  style: {},
  length: 4,
  onChange: () => {},
  codes: CODES
};
