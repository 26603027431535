import React, { Component } from 'react';
import GlobalBreadcrumb from '../components/common/GlobalBreadcrumb';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import WrapperButton from '../components/common/WrapperButton';
import ImgCard from '../components/common/ImgCard';
import BlockCard from '../components/common/BlockCard';
import { ScrollTo } from '../script/Utils';
import { NewsData } from '../script/MockDatas';
import { Row, Col, Carousel } from 'antd';

const data = {
    section1: {
        title: '关于游龙科技',
        desc: '19年研发经验, 业界领先服务保证',
        img: '关于游龙-Banner.jpg',
        btn1: { text: '免费云试用版', to: "/OnlineExperience" },
        btn2: { text: '免费下载试用', to: '/Download' },
    },
    section2: {
        title: '公司能力',
        desc: '十九年来，基于稳定最强研发团队，持续投入创新，采用最优秀的软件架构，出品简约大方的风格界面。',
        items: [
            { 
                img: '解决方案-ITSS工具.jpg',
                title: '技术优势', 
                tip: '',
                content: '在技术上构建宏观一体化平台，动态插件模式，微观高可配置性的系统。产品基于ITIL框架与标准，以全面深度监测IT基础架构为核心，深化拓展运维、流程、资产管理，创新海量日志实时分析平台；以IT管理原生态为基础，面向业务、为用户延伸云计算、云监测、微信App监测、手机App智能运维、大数据的服务平台和IT运维管理可视化、人工智能深度学习以及商业智能、一体化平台的IT运营支撑系统解决方案。',
                btn: {
                    text: '查看产品',
                    to: '/Product'
                },
                config: {
                    type: 'vertical'
                }
            },
            { 
                img: '关于游龙-发展方向.png',
                title: '发展方向', 
                tip: '',
                content: '坚持全面深度监测，并持续创新，引领行业航 向；深度日志分析、海量运维，展开广度、深 度上的技术延伸和优化方案；大数据云、机器 学习、人工智能、万物互联等多重技术与前沿 黑科技实现IT运营智能和商业智能。',
                btn: {
                    text: '查看解决方案',
                    to: '/Solutions'
                },
                config: {
                    type: 'vertical'
                }
            },
            { 
                img: '解决方案-企业业务协同.jpg',
                title: '服务能力', 
                tip: '',
                content: '拥有全国20多家销售技术支撑中心，数百家合 作伙伴，400呼叫中心，在线客服与技术支持，快速积极响应用户，主动巡检回访服务，并对友商渠道、市场、培训、售后等一系列品牌、技术、案例经验上的服务对接与精细化支持。',
                btn: {
                    text: '查看用户案例',
                    to: '/CustomerCase'
                },
                config: {
                    type: 'vertical',
                }
            },
        ],
        btns: [
            { text: '在线演示 ECC', to: 'https://demo.siteview.com', target: '_blank' },
            { text: '在线演示 ITSM', to: 'https://eccqa.weadmin.com', target: '_blank' }
        ]
    },
    section3: {
        title: '公司版图',
        desc: '十九年来，基于稳定最强研发团队，持续投入创新，采用最优秀的软件架构，出品简约大方的风格界面。',
        img: '关于游龙-公司版图.jpg'
    },
    section4: {
        img: '关于游龙-诚聘英才.jpg',
        title: '诚聘英才', 
        tip: '',
        content: `<div style="margin-bottom: 20px; font-size: 18px">加入游龙，成就你的职业</div><div>最稳定最强研发团队<br/>最优秀的技术架构<br/>等你来加入</div>`,
        btn: {
            text: '查看在招职位',
            to: '/About/Advertises'
        },
        config: {
            type: 'horizontal',
            imgAlign: 'right',
        }
    },
    section5: {
        title: '新闻中心',
        desc: '游龙大事记、技术更新公告等，来新闻中心，探索更多游龙新动态。',
        items: NewsData,
        btn: { text: '查看全部新闻', to: '/About/NewsCenter' }
    }
};

export default class About extends Component {

    componentDidMount() {
        ScrollTo();
    }

    render() {
        let carouselItems = [];
        for (let i = 0; i < data.section5.items.length; i += 3) {
            carouselItems.push(data.section5.items.slice(i, i + 3));
        }
        return (
            <div className="aboutView">
                <div className="breadcrumb-wrapper"><GlobalBreadcrumb {...this.props} /></div>
                <MainSectionDisplay
                    img={data.section1.img}
                    title={data.section1.title}
                    desc={data.section1.desc}
                    btns={[data.section1.btn1, data.section1.btn2]}
                    descStyle={{margin: '35px auto 50px'}}
                />
                <div className="about-section2">
                    <MainSectionDisplay
                        title={data.section2.title}
                        desc={data.section2.desc}
                        titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                        descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px', marginBottom: '5px'}}
                    />
                    <div className="card-container">
                        <Row gutter={{sm: 0, md: 19}} type="flex">
                            {data.section2.items.map((item, index) => {
                                return (
                                    <Col key={index} sm={24} md={12} lg={8} style={{marginBottom: '19px'}}>
                                        <div className="card-item">
                                            <ImgCard {...item} />
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {data.section2.btns.map((btn, index) => (
                            <WrapperButton key={index} className="linearBorderBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                        ))}
                    </div>
                </div>
                <div className="about-section3">
                    <MainSectionDisplay
                        title={data.section3.title}
                        desc={data.section3.desc}
                        titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                        descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px', marginBottom: '5px'}}
                    />
                    <div className="about-section3-img-wrapper">
                        <img src={require(`../images/${data.section3.img}`)} alt=""/>
                    </div>
                </div>
                <div className="about-section4">
                    <ImgCard
                        imgHeight="458px"
                        {...data.section4}
                        content={<div dangerouslySetInnerHTML={{__html: data.section4.content}}></div>}
                    />
                </div>
                <div className="about-section5">
                    <MainSectionDisplay
                        title={data.section5.title}
                        desc={data.section5.desc}
                        titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                        descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px', marginBottom: '5px'}}
                    />
                    <div className="carousel-wrapper">
                        <Carousel autoplay>
                            {carouselItems.map((item, index) => {
                                return (
                                    <div key={index} className="carousel-item">
                                        <Row gutter={19} type="flex" justify="center" style={{marginTop: '15px', marginBottom: '15px'}}>
                                            {item.map((p, iIndex) => (
                                                <Col key={iIndex} span={6} >
                                                    <div style={{background: '#fff', height: '100%'}}>
                                                        <BlockCard {...p} imgHeight="276px" />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div style={{textAlign: 'center', marginTop: '30px'}}>
                        <WrapperButton className="linearBorderBtn" style={{width: '221px'}} btn={data.section5.btn}>{data.section5.btn.text}</WrapperButton>
                    </div>
                </div>
                {/*<div style={{padding: '0 45px', marginBottom: '-45px'}}>
                    <GridDisplay items={data.section2.items} />
                </div>
                <MainSectionDisplay
                    title={data.section3.title}
                    desc={data.section3.desc}
                    titleStyle={{marginTop: '62px', fontSize: '30px', lineHeight: '42px'}}
                    descStyle={{marginTop: '29px', fontSize: '18px', lineHeight: '25px'}}
                />
                <div style={{padding: '0 45px', marginBottom: '-45px'}}>
                    <GridDisplay items={data.section3.items} />
                </div>*/}
            </div>
        )
    }
}
