import React, { Component } from 'react';
import WrapperButton from '../components/common/WrapperButton';
import IconFont from '../components/common/IconFont';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import { ScrollTo } from '../script/Utils';
import $ from 'jquery';
import { Row, Col, Form, Button, Input, notification } from 'antd';

const data = {
    section1: {
        title: '获取解决方案',
        desc: '游龙科技19年专业技术，很高兴为您的企业量身定制解决方案',
        btns: [
            { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
            { text: '免费下载试用', to: '/Download' }
        ]
    }
};

export default class GetSolutions extends Component {

    componentDidMount() {
        ScrollTo();
    }

    render() {
        return (
            <div className="downloadView">
                <div className="download-section1">
                    <div className="light-content" style={{paddingBottom: '25px'}}>
                        <MainSectionDisplay
                            title={data.section1.title}
                            desc={data.section1.desc}
                            titleStyle={{marginTop: '62px', textAlign: 'left', width: '85%', marginLeft: 'auto', marginRight: 'auto'}}
                            descStyle={{marginTop: '29px', marginBottom: '5px', textAlign: 'left', width: '85%'}}
                        />
                    </div>
                    <Row type="flex">
                        <Col sm={24} md={12}>
                            <div className="download-form-img" style={{backgroundImage: `url(${require('../images/首页-ITSM.jpg')})`}}>
                                <div className="download-img-mask"></div>
                                <div className="download-form-container">
                                    <div className="download-img-title">我们的优势</div>
                                    <IconFont type="sv-diqiuyi" />
                                    <div className="download-img-desc-item">在技术上构建宏观一体化平台，动态插件模式，微观高可配置性的系统。</div>
                                    <IconFont type="sv-hailiangxiansuo" />
                                    <div className="download-img-desc-item">产品基于ITIL框架与标准，以全面深度监测IT基础架构为核心，深化拓展运维、流程、资产管理，创新海量日志实时分析平台。</div>
                                    <IconFont type="sv-yunjisuan" />
                                    <div className="download-img-desc-item">以IT管理原生态为基础，面向业务、为用户延伸云计算、云监测、微信App监测、手机App智能运维、大数据的服务平台和IT运维管理可视化、人工智能深度学习以及商业智能、一体化平台的IT运营支撑系统解决方案。</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={24} md={12}>
                            <div className="form-wrapper">
                                <div className="download-form-title">
                                    请正确填写以下信息，我们将会在收到信息的第一时间回复您
                                </div>
                                <WrapperForms />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{textAlign: 'center', marginTop: '45px'}}>
                    {data.section1.btns.map((btn, index) => {
                        return (
                            <WrapperButton key={index} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                        )
                    })}
                </div>
            </div>
        )
    }
}

class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    validatePhone = (rule, value, callback) => {
        if (value === '') {
            callback();
        } else if (
          !/^1[34578]\d{9}$/.test(value) &&
          !/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)
        ) {
          callback("输入的联系电话无效！");
        } else {
          callback();
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            // console.log('Received values of form: ', values);
            this.setState({loading: true});
            $.ajax({
                type: "post",
                url: `https://eccqa.weadmin.com/reguserinfo/add`,
                dataType: "json",
                data: values,
                success: result => {
                  if (result && result.code === 0) {
                    notification.success({
                      message: "提交成功",
                      description: "感谢您提交申请，30分钟内客服经理会与您联系！"
                    });
                    this.props.form.resetFields();
                  } else {
                    notification.error({
                      message: "申请失败",
                      description: "请稍后再试！"
                    });
                  }
                  this.setState({loading: false});
                },
                error: function(err) {
                  notification.error({
                    message: "申请失败",
                    description: "请稍后再试！"
                  });
                  this.setState({loading: false});
                }
              });
          }
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 6,
              },
            },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="normal-form" {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="公司名称">
                    {getFieldDecorator('CompanyName', {
                        rules: [
                            {
                                required: true,
                                message: '请输入您的公司名称',
                            }
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="联系人">
                    {getFieldDecorator('ContactPerson', {
                        rules: [
                            {
                                required: true,
                                message: '请输入联系人',
                            }
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="联系电话">
                    {getFieldDecorator('PhoneNumber', {
                        rules: [
                            {
                                required: true,
                                message: '请输入您的联系电话',
                            },
                            {
                              validator: this.validatePhone
                            }
                        ],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="企业邮箱">
                    {getFieldDecorator('Email', {
                        rules: [
                            {
                                type: 'email',
                                message: '请输入正确的邮箱',
                            },
                            {
                                required: true,
                                message: '请输入您的邮箱',
                            },
                        ],
                    })(<Input />)}
                </Form.Item>
                {/* <Form.Item label="企业类型">
                    {getFieldDecorator('product', {
                        rules: [
                            {
                                required: true,
                                message: '请选择一个企业类型',
                            }
                        ],
                    })(
                        <Select>
                            <Select.Option value="ECC">ECC</Select.Option>
                            <Select.Option value="ITSM">ITSM</Select.Option>
                        </Select>
                    )}
                </Form.Item> */}
                <Form.Item label="需求描述">
                    {getFieldDecorator('DemandDes', {
                        rules: [{ required: true, message: '请输入您的需求描述' }],
                    })(<Input.TextArea rows={6} />)}
                </Form.Item>
                {/* <Form.Item {...tailFormItemLayout}>
                    {getFieldDecorator('agreement', {
                        valuePropName: 'checked',
                    })(
                        <Checkbox>
                            请确认您已阅读我们的<a href="" style={{textDecoration: 'underline'}}>安全须知</a>，我们承诺尊重及保证您的信息安全及隐私，保障您的个人信息权益。
                        </Checkbox>,
                    )}
                </Form.Item> */}
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={this.state.loading} type="primary" htmlType="submit">提交</Button>
                </Form.Item>
            </Form>
        )
    }
}

const WrapperForms = Form.create()(Forms);
