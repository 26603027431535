import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WrapperButton from '../components/common/WrapperButton';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import GlobalMarkdown from '../markdown/GlobalMarkdown';
import { ScrollTo } from '../script/Utils';
import { Row, Col, Tabs } from 'antd';

const { TabPane } = Tabs;

const data = {
    title: 'SITEVIEW IT基础设施监控',
    tabs: [
        {
            title: '概述',
            pane: {
                section1: {
                    title: 'SITEVIEW IT基础设施监控',
                    img: 'stock-1863880_960_720.jpg',
                    desc: '专注性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
                    btns: [
                        { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
                        { text: '免费下载试用', to: '/Download' },
                    ]
                },
                section2: {
                    title: 'SITEVIEW ECC 概述',
                    desc: 'SITEVIEW ECC 专注于对局域网、广域网和互联网上的各种异构网络基础架构及其应用的故障监测和性能管理，是集中式、非代理、跨平台监测的系统管理软件， 可以对各种操作系统下的服务器、各厂家的网络设备、各类数据库、中间件、邮件系统、WEB系统、电子商务以及各种企业应用进行全面深入的监测管理。',
                    items: [
                        { title: '10,000+ 用户', content: '上万用户的使用，全中文WEB界面，友好易用，优秀的Eclipse OSGi插件架构提高用户使用感' },
                        { title: '19 年', content: '19年在技术、品牌、服务方面一直遥遥领先，始终引领国内IT运维领域风向标' },
                        { title: '5分钟', content: '5分钟下载、安装、部署所有应用、数据库和Web服务器，马上看到运行效果' },
                    ],
                    btns: [
                        { text: '查看功能点介绍' },
                        { text: '查看监测点介绍' },
                    ]
                },
                section3: {
                    title: '系统界面展示',
                    tabs: [
                        { title: '管理控制台', img: 'glkzt.png' },
                        { title: '报警设置', img: 'bjsz.png' },
                        { title: '资源设置', img: 'zysz.PNG' },
                        { title: '立体拓扑', img: 'nttp.png' },
                        { title: '状态巴图', img: 'ztbt.png' },
                        { title: '矩阵树图', img: 'jzst.png' },
                        { title: '微信小程序', img: 'wxxcx.png' },
                        { title: '操作审计', img: 'czsj.png' },
                    ]
                },
                section4: {
                    title: '安装与应用需求',
                    desc: '多系统兼容，工作环境多样化',
                    props: {
                        '硬盘': '50+ GB',
                        '内存': '8+ GB',
                        'CPU': '4+ 核',
                        '操作系统': '64位操作系统：支持Windows（除系统WIN2000，WIN2003外，这两个系统不支持安装JDK 1.8 64位以上的版本） 各版本，CentOS,RedHat ,Mac 操作系统，推荐Centos6.5 64位操作系统。',
                        '数据库': '数据库、大数据及数据库集群: 标准支持Mysql(试用时无需安装，自带嵌入式mysql数据库，直接使用即可) 定制支持MS Sql ,Oracle ,Sysbase ,Hadoop ,Elasticsearch',
                        '准备环境': 'JDK1.8及以上版本，并配置好java环境变量，可执行java -version确认JDK版本和路径。',
                        '浏览器': 'IE9+ ; Google Chrome 29+ ; Firefox 23+ ; Safari 6+ ; Microsoft Edge 1.0+ ; Opera 15+'
                    },
                    btns: [
                        { text: '免费下载试用', to: '/Download' }
                    ]
                }
            }
        },
        {
            title: '功能点介绍',
            mdPath: 'ProductEccFunction',
            pane: {
                title: 'ECC功能点介绍',
                desc: '专注性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
                verticalTabs: [
                    { title: '简单易用、操作简便', content: `
                        <div style="line-height: 60px; font-size: 16px; color: #0a0a0a;">
                            <div style="font-size: 20px;">采用Windows交互式图形界面，通过鼠标、键盘的简单操作，５分钟内就能完成整个系统的安装，操作如同使用Office一样简单方便。 </div>
                            <img style="width: 100%" src="${require('../images/ECC-系统界面展示.png')}" alt="" />
                            <div style="text-align: center;">资源监测列表视图</div>
                            <img style="width: 100%" src="${require('../images/产品中心-ECC功能点.png')}" alt="" />
                        </div>
                    `},
                    { title: '非代理模式监测服务器', content: '' },
                    { title: '企业级高效监测', content: '' },
                    { title: '炫酷仪表盘', content: '' },
                    { title: '立体拓扑', content: '' },
                    { title: '运营状态可视化', content: '' },
                    { title: '微信小程序', content: '' },
                    { title: '多终端方式告警', content: '' },
                    { title: '丰富多样报表', content: '' },
                    { title: '操作审计', content: '' },
                    { title: '平台自检', content: '' },
                    { title: '数据备份与恢复', content: '' },
                    { title: '多组织、多角色、多用户管理', content: '' }
                ],
                btns: [
                    { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
                    { text: '免费下载试用', to: '/Download' },
                ]
            }
        },
        {
            title: '监测点介绍',
            mdPath: 'ProductEccMonitor',
            pane: {
                title: 'ECC监测点介绍',
                desc: '专注性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
                verticalTabs: [
                    { title: '监测器介绍', content: `
                        <div style="line-height: 60px; font-size: 16px; color: #0a0a0a;">
                            <div>有10000+监测器，CPU、内存、Disk、目录、文件、Ping、端口、服务、进程、网卡、系统事件日志、VMOracle数据库信息、Oracle表空间、Oracle连接数、SQLServer事务及日志、SQLServer死锁、数据库查询、WebSphereMQSeries等等，并可进行监测器个性化定制开发。</div>
                            <img style="width: 100%" src="${require('../images/ECC-系统界面展示.png')}" alt="" />
                            <div style="text-align: center;">资源监测列表视图</div>
                            <img style="width: 100%" src="${require('../images/产品中心-ECC功能点.png')}" alt="" />
                        </div>
                    `},
                    { title: '监测器界面介绍', content: '' },
                    { title: '对网络设备支持', content: '' },
                    { title: '对服务器支持', content: '' },
                    { title: '对业务系统支持', content: '' }
                ],
                btns: [
                    { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
                    { text: '免费下载试用', to: '/Download' },
                ]
            }
        }
    ]
};
export default class ProductEcc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            active: 0,
        };
    }

    componentDidMount() {
        ScrollTo();
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center" style={{display: 'flex', justifyContent: 'center'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    onSetTab = tab => {
        ScrollTo();
        this.onTabChange(tab);
    }

    onTabChange = tab => {
        this.setState({tab}, () => {
            this.setState({active: tab});
        });
    }

    render() {
        const { tab, active } = this.state;
        return (
            <div className="productView">
                <Row className="product-section1-header" type="flex" align="middle">
                    <Col md={24} lg={12}>
                        <span className="title">{data.title}</span>
                        <Link to="/ProductItsm" style={{marginLeft: '30px'}}>ITSM</Link>
                    </Col>
                    <Col md={24} lg={12}>
                        <div className="func-tab">
                            {data.tabs.map((t, index) => (
                                <span key={index} className={tab === index ? 'active': ''} onClick={()=>this.onTabChange(index)}>{t.title}</span>
                            ))}
                        </div>
                    </Col>
                </Row>
                {data.tabs.map((tt, index) => {
                    return (
                        <div className={"tab-pane-wrapper" + (active === index ? ' active': '')} key={index} style={{display: tab === index ? 'block': 'none'}}>
                            {tt.pane.hasOwnProperty('verticalTabs') ? (
                                <>
                                    <MainSectionDisplay
                                        style={{background: '#fff'}}
                                        title={tt.pane.title}
                                        desc={tt.pane.desc}
                                        titleStyle={{marginTop: '63px'}}
                                        descStyle={{marginTop: '20px'}}
                                    />
                                    <div className="verticalTab-wrapper">
                                        <Tabs defaultActiveKey="0" tabPosition="left">
                                            {tt.pane.verticalTabs.map((item, iIndex) => (
                                                <TabPane tab={<span className="tab-title">{item.title}</span>} key={iIndex}>
                                                    <div className="content-title"><span>{item.title}</span></div>
                                                    <div className="content-container">
                                                        <GlobalMarkdown 
                                                            markdown={require(`../markdown/${tt.mdPath}/tab-${iIndex+1}/index.md`)}
                                                            imgPath={`./${tt.mdPath}/tab-${iIndex+1}/`}
                                                        />
                                                    </div>
                                                </TabPane>
                                            ))}
                                        </Tabs>
                                    </div>
                                    <div style={{textAlign: 'center', marginTop: '45px'}}>
                                        {tt.pane.btns.map((btn, bIndex) => {
                                            return (
                                                <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                            )
                                        })}
                                    </div>
                                </>
                            ): (
                                <>
                                    <MainSectionDisplay
                                        img={tt.pane.section1.img}
                                        style={{background: '#fff', paddingBottom: '64px'}}
                                        title={tt.pane.section1.title}
                                        desc={tt.pane.section1.desc}
                                        titleStyle={{marginTop: '63px'}}
                                        descStyle={{marginTop: '20px'}}
                                        btns={tt.pane.section1.btns}
                                    />
                                    <div className="product-section2">
                                        <MainSectionDisplay
                                            title={tt.pane.section2.title}
                                            desc={tt.pane.section2.desc}
                                            titleStyle={{marginTop: '63px', fontSize: '30px'}}
                                            descStyle={{marginTop: '20px', fontSize: '18px'}}
                                        />
                                        <Row gutter={{sm: 0, md: 0}} type="flex" justify="center">
                                            {tt.pane.section2.items.map((item, mIndex) => (
                                                <Col key={mIndex} sm={24} md={5}>
                                                    <div className="item-title"><span>{item.title}</span></div>
                                                    <div className="item-content"><span>{item.content}</span></div>
                                                </Col>
                                            ))}
                                        </Row>
                                        <div style={{textAlign: 'center', marginTop: '30px'}}>
                                            {tt.pane.section2.btns.map((btn, mbIndex) => {
                                                return (
                                                    <WrapperButton key={mbIndex} className="linearBorderBtn" style={{width: '221px', margin: '0 15px'}} onClick={() => this.onSetTab(mbIndex + 1)}>{btn.text}</WrapperButton>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="product-section3">
                                        <Row type="flex" justify="center">
                                            <Col md={24} lg={20} xl={18}>
                                                <div className="normal-section-title">{tt.pane.section3.title}</div>
                                                <Tabs defaultActiveKey="0" renderTabBar={this.renderTabBar}>
                                                    {tt.pane.section3.tabs.map((tab, sIndex) => (
                                                        <TabPane tab={tab.title} key={sIndex}>
                                                            <div className="tab-img-wrapper">
                                                                <img className="tab-img" src={require(`../images/${tab.img}`)} alt=""/>
                                                            </div>
                                                        </TabPane>
                                                    ))}
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-section4">
                                        <MainSectionDisplay
                                            title={tt.pane.section4.title}
                                            desc={tt.pane.section4.desc}
                                            titleStyle={{marginTop: '63px', fontSize: '30px'}}
                                            descStyle={{marginTop: '20px', fontSize: '18px'}}
                                        />
                                        <div className="props-wrapper">
                                            {Object.keys(tt.pane.section4.props).map((key, kIndex) => (
                                                <div className="props-item" key={kIndex}>
                                                    <div className="props-item-title">{key}</div>
                                                    <div className="props-item-content">{tt.pane.section4.props[key]}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{textAlign: 'center', marginTop: '45px'}}>
                                            {tt.pane.section4.btns.map((btn, mbIndex) => {
                                                return (
                                                    <WrapperButton key={mbIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }
}
