import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from './pages/Home';
import Product from './pages/Product';
import Solutions from './pages/Solutions';
import SolutionsSubView from './pages/SolutionsSubView';
import CustomerCase from './pages/CustomerCase';
import CustomerCaseSubView from './pages/CustomerCaseSubView';
import About from './pages/About';
import QuickStart from './pages/QuickStart';
import Advertises from './pages/Advertises';
import NewsCenter from './pages/NewsCenter';
import NewsSubView from './pages/NewsSubView';
import OnlineExperience from './pages/OnlineExperience';
import ProductEcc from './pages/ProductEcc';
import ProductItsm from './pages/ProductItsm';
import FAQ from './pages/FAQ';
import Download from './pages/Download';
import GetSolutions from './pages/GetSolutions';
import Subscribe from './pages/Subscribe';
import FreeSource from './pages/FreeSource';

function NoMatch({ location }) {
  console.log('---------------- nomatch for ' + location.pathname);
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default class AppRoutes extends React.Component {
    
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/Product" exact component={Product} />
                <Route path="/ProductEcc" exact component={ProductEcc} />
                <Route path="/ProductItsm" exact component={ProductItsm} />
                <Route path="/Solutions" exact component={Solutions} />
                <Route path="/Solutions/:title" component={SolutionsSubView} />
                <Route path="/CustomerCase" exact component={CustomerCase} />
                <Route path="/CustomerCase/:title" component={CustomerCaseSubView} />
                <Route path="/QuickStart" exact component={QuickStart} />
                <Route path="/OnlineExperience" component={OnlineExperience} />
                <Route path="/About" exact component={About} />
                <Route path="/About/Advertises" component={Advertises} />
                <Route path="/About/NewsCenter" exact component={NewsCenter} />
                <Route path="/About/NewsCenter/:title" component={NewsSubView} />
                <Route path="/FAQ" component={FAQ} />
                <Route path="/Download" component={Download} />
                <Route path="/GetSolutions" component={GetSolutions} />
                <Route path="/Subscribe" component={Subscribe} />
                <Route path="/FreeSource" component={FreeSource} />
                <Route component={NoMatch} />
            </Switch> 
        )
    }

}