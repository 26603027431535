import React, { Component } from 'react';
import GlobalBreadcrumb from '../components/common/GlobalBreadcrumb';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import GridDisplay from '../components/common/GridDisplay';
import { ScrollTo } from '../script/Utils';
import { CustomerCaseData } from '../script/MockDatas';
import { Tabs, Row, Col } from 'antd';

const { TabPane } = Tabs;

const data = {
    section1: {
        img: '客户案例-Banner.jpg',
        title: '客户案例',
        desc: '面向业务、以用户为导向',
        btn1: { text: '免费云试用版', to: "/OnlineExperience" },
        btn2: { text: '免费下载试用', to: '/Download'},
    },
    section2: {
        title: '20,000+ 用户的选择',
        tabs: []
    }
};
let keys = [];
let tabs = [];
CustomerCaseData.forEach(item => {
    let index = keys.indexOf(item.type);
    if (index > -1) {
        tabs[index].items.push(item);
    } else {
        keys.push(item.type);
        tabs.push({
            title: item.type,
            items: [item]
        });
    }
});
data.section2.tabs = tabs;

export default class CustomerCase extends Component {

    componentDidMount() {
        ScrollTo();
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center" style={{display: 'flex', justifyContent: 'center'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    render() {
        return (
            <div className="customerCaseView">
                <div className="breadcrumb-wrapper"><GlobalBreadcrumb {...this.props} /></div>
                <MainSectionDisplay
                    img={data.section1.img}
                    title={data.section1.title}
                    desc={data.section1.desc}
                    btns={[data.section1.btn1, data.section1.btn2]}
                    descStyle={{margin: '35px auto 50px'}}
                />
                <div className="customerCase-section2">
                    <Row type="flex" justify="center">
                        <Col md={24} lg={20} xl={18}>
                            <div className="normal-section-title">{data.section2.title}</div>
                            <Tabs defaultActiveKey="0" renderTabBar={this.renderTabBar}>
                                {data.section2.tabs.map((tab, index) => (
                                    <TabPane tab={tab.title} key={index}>
                                        <GridDisplay items={tab.items} shadow gutter={{xs: 0, sm: 19}} spanRules={{xs: 24, sm: 12, xl: 8, xxl: 6}} verticalGutter="19px" />
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
