import React from "react";
import { BrowserRouter } from "react-router-dom";
import { StickyContainer } from 'react-sticky';
import AppRoutes from './app-routes';
import GlobalHeader from './components/layout/GlobalHeader';
import GlobalFooter from './components/layout/GlobalFooter';
import ContactUs from './components/common/ContactUs';
import './scss/app.scss';
import { Layout, BackTop } from 'antd';

const { Content } = Layout;

function App() {
  return (
    <BrowserRouter>
      <StickyContainer>
        <Layout className="app-container">
          <GlobalHeader />
          <Content className="app-content">
            <AppRoutes />
          </Content>
          <ContactUs />
          <GlobalFooter />
          <BackTop visibilityHeight={200} />
        </Layout>
      </StickyContainer>
    </BrowserRouter>
  );
}

export default App;