import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const breadcrumbNameMap = {
    '/Solutions': '解决方案',
    '/CustomerCase': '客户案例',
    '/About': '关于游龙',
    '/QuickStart': '快速上手',
    '/OnlineExperience': '在线体验',
    '/About/Advertises': '诚聘英才',
    '/About/NewsCenter': '新闻中心'
};

function GlobalBreadcrumb({ location, pathMap, match }) {
    // console.log(location, match);
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const _pathMap = Object.assign({}, breadcrumbNameMap, pathMap || {});
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        let label = '';
        if (match.params.hasOwnProperty('title') && url.indexOf(match.params.title) > 0) {
            label = match.params.title;
        } else {
            label = _pathMap[url];
        }
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{label}</Link>
            </Breadcrumb.Item>
        );
    });
    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <Link to="/">首页</Link>
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
    return (
        <Breadcrumb style={{lineHeight: '30px', color: '#000'}}>{breadcrumbItems}</Breadcrumb>
    )
}

export default GlobalBreadcrumb;