import React, { Component } from 'react';
import IconFont from './IconFont';
import { Row, Col } from 'antd';

const data = {
    title: '联系我们',
    secondTitle: '期待与您的合作',
    desc: '欢迎您来电咨询，我们的专家随时准备着帮助您选择最合适您需求的解决方案和价格，马上call我们吧。',
    contact: {
        number: '+86 - 13520830552',
        name: '张经理'
    }
};

export default class ContactUs extends Component {
    render() {
        return (
            <div className="contactUs">
                <Row gutter={{md: 0, lg: 50}} type="flex" align="middle">
                    <Col md={24} lg={16}>
                        <div className="normal-section-title title">{data.title}</div>
                        <div className="section-second-title">{data.secondTitle}</div>
                        <div className="normal-section-desc desc">{data.desc}</div>
                    </Col>
                    <Col md={24} lg={8}>
                        <div className="right">
                            <span style={{color: '#1890ff'}}><IconFont type="sv-dianhua" /></span>
                            <span>{data.contact.number}</span>
                            <span>{data.contact.name}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
