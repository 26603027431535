import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WrapperButton from '../components/common/WrapperButton';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import ImgCard from '../components/common/ImgCard';
import { ScrollTo } from '../script/Utils';
import { Row, Col } from 'antd';

const data = {
    section1: {
        headers: [
            { text: 'SITEVIEW ECC IT基础设施监控', to: '/ProductEcc' },
            { text: 'SITEVIEW IT服务管理', to: '/ProductItsm' },
        ],
        title: '产品中心',
        desc: '专注性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
        items: [
            { 
                img: '首页-ECC.jpg',
                title: 'IT基础设施监控', 
                tip: ' (非代理式)',
                content: '专注对局域网、广域网和互联网上的服务器网络设备、业务应用系统等性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
                btn: [
                    {
                        text: '查看更多',
                        to: '/ProductEcc'
                    },
                    {
                        text: '在线体验',
                        to: 'https://demo.siteview.com',
                        target: '_blank'
                    },
                ],
                config: {
                    type: 'vertical'
                }
            },
            { 
                img: '首页-ITSM.jpg',
                title: 'IT服务管理', 
                tip: '',
                content: '以ITIL最佳实践为基础，满足ISO20000，COBIT等标准，基于工单的流程化标准管理，故障处理跟踪分析、运营效益考核分析等易用的IT服务管理系统。',
                btn: [
                    {
                        text: '查看更多',
                        to: '/ProductItsm'
                    },
                    {
                        text: '在线体验',
                        to: 'https://eccqa.weadmin.com',
                        target: '_blank'
                    }
                ],
                config: {
                    type: 'vertical'
                }
            },
            { 
                img: '产品中心-ECC功能点.png',
                title: '稳定核心应用、提高可用度', 
                tip: '',
                content: '帮助IT运维人员全局把握IT系统，实现监测自动化，预警故障、快速定位、解决故障，确保业务系统的稳定运行，并提供完善的性能报表，定位运行瓶颈，及时扩容升级。',
                btn: {
                    text: '查看更多ECC功能',
                    to: '/ProductEcc'
                },
                config: {
                    type: 'horizontal',
                    imgAlign: 'left'
                }
            },
            { 
                img: 'ITSM_tu1.png',
                title: '低成本、灵活配置实现IT服务管理全生命周期的交付', 
                tip: '',
                content: '快速恢复IT资源的正常运行，避免业务中断，使事故对业务运营的影响降至最低，通过流程引擎配置事件流转过程，自动流转无需人工干预。',
                btn: {
                    text: '查看更多ITSM功能',
                    to: '/ProductItsm'
                },
                config: {
                    type: 'horizontal',
                    imgAlign: 'right',
                }
            }
        ],
        intro: '欢迎您先试用我们的在线DEMO，简易配置，快速上手，无需担心复杂操作',
        btns: [
            { text: '免费云试用版(ECC)', to: "https://demo.siteview.com", target: '_blank' },
            { text: '免费云试用版(ITSM)', to: "https://eccqa.weadmin.com", target: '_blank' },
            { text: '免费下载试用', to: '/Download' },
        ]
    }
}

export default class Product extends Component {

    componentDidMount() {
        ScrollTo();
    }

    render() {
        return (
            <div className="productView">
                <div className="product-section1">
                    <Row className="product-section1-header" type="flex" align="middle" justify="center">
                        {data.section1.headers.map((header, index) => {
                            return (
                                <Col key={index} style={{cursor: 'pointer', padding: '0 16px'}}>
                                    <Link to={header.to}>{header.text}</Link>
                                </Col>
                            )
                        })}
                    </Row>
                    <MainSectionDisplay 
                        title={data.section1.title}
                        desc={data.section1.desc}
                        titleStyle={{marginTop: '63px'}}
                        descStyle={{marginTop: '20px'}}
                    />
                    <div className="card-container">
                        <Row gutter={{md: 0, lg: 45}} type="flex">
                            {data.section1.items.map((item, index) => {
                                let _lg = 12;
                                if (item.config.type === 'horizontal') {
                                    _lg = 24;
                                }
                                return (
                                    <Col key={index} md={24} lg={_lg} style={{marginBottom: '45px'}}>
                                        <div className="card-item">
                                            <ImgCard {...item} imgHeight="430px" />
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                    <div className="product-section1-intro">{data.section1.intro}</div>
                    <div style={{textAlign: 'center', marginTop: '30px'}}>
                        {data.section1.btns.map((btn, index) => {
                            return (
                                <WrapperButton key={index} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
