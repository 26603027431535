import React, { Component } from "react";
import Captcha from "../components/common/Captcha";
import IconFont from "../components/common/IconFont";
import MainSectionDisplay from "../components/common/MainSectionDisplay";
import { ScrollTo } from "../script/Utils";
import $ from "jquery";
import {
  Row,
  Col,
  Form,
//   Checkbox,
  Button,
//   Select,
  Input,
  Table,
  notification
} from "antd";

const data = {
  section1: {
    title: "免费下载试用",
    desc: "资料与软件下载"
  },
  section2: {
    title: "版本一览",
    desc:
      "安装过程完全自动化了，十分便捷，绿色版解压后，可直接在WINDOWS、LINUX、MAC下运行",
    items: [
      {
        counts: "1-100",
        cpu: "普通双核 CPU2.0HZ",
        memary: "2G",
        frame: "30G-40G",
        os: "Windows, CentOS, Redhat, Ubuntu"
      },
      {
        counts: "100-200",
        cpu: "普通双核 CPU2.5HZ",
        memary: "4G",
        frame: "60G-80G",
        os: "Windows, CentOS, Redhat, Ubuntu"
      },
      {
        counts: "200-500",
        cpu: "INTEL4核 CPU3.0HZ",
        memary: "8G",
        frame: "150G-200G",
        os: "Windows, CentOS, Redhat, Ubuntu"
      },
      {
        counts: "500-1000",
        cpu: "INTEL4核 CPU3.0HZ",
        memary: "16G",
        frame: "200G-300G",
        os: "Windows, CentOS, Redhat, Ubuntu"
      },
      {
        counts: "1000-2000",
        cpu: "INTEL8核 CPU3.0HZ",
        memary: "32G",
        frame: "300G-500G",
        os: "Windows, CentOS, Redhat, Ubuntu"
      },
      {
        counts: "2000-5000",
        cpu: "INTEL8核 CPU3.0HZ",
        memary: "64G",
        frame: "500G-1T",
        os: "WCentOS7"
      }
    ]
  }
};

export default class Download extends Component {
  render() {
    const columns = [
      { title: "监控点数", dataIndex: "counts" },
      { title: "CPU", dataIndex: "cpu" },
      { title: "内存", dataIndex: "memary" },
      { title: "架构 ", dataIndex: "frame" },
      { title: "操作系统", dataIndex: "os" }
    ];
    return (
      <div className="downloadView">
        <div className="download-section1">
          <div className="light-content" style={{ paddingBottom: "25px" }}>
            <MainSectionDisplay
              title={data.section1.title}
              desc={data.section1.desc}
              titleStyle={{
                marginTop: "62px",
                textAlign: "left",
                width: "85%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              descStyle={{
                marginTop: "29px",
                marginBottom: "5px",
                textAlign: "left",
                width: "85%"
              }}
            />
          </div>
          <Row type="flex">
            <Col sm={24} md={12}>
              <div
                className="download-form-img"
                style={{
                  backgroundImage:
                    "url(" + require("../images/首页-ITSM.jpg") + ")"
                }}
              >
                <div className="download-img-mask" />
                <div className="download-form-container">
                  <div className="download-img-title">软件亮点</div>
                  <IconFont type="sv-SqlServer" />
                  <div className="download-img-desc-item">
                    SITEVIEW ECC 默认使用内嵌MYSQL数据库
                  </div>
                  <IconFont type="sv-service-fill" />
                  <div className="download-img-desc-item">
                    试用期间享受免费专业人员技术支持
                  </div>
                  <IconFont type="sv-zuixingengxin" />
                  <div className="download-img-desc-item">
                    及时获取更新适配版本的下载地址
                  </div>
                  <div className="download-img-link">
                    点击这里查看ECC V2.0 Beta版本更新说明
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div className="form-wrapper">
                <div className="download-form-title">
                  请正确填写以下信息，我们将会在收到信息的第一时间回复您
                </div>
                <WrapperForms />
              </div>
            </Col>
          </Row>
        </div>
        <div className="download-section2">
          <MainSectionDisplay
            title={data.section2.title}
            desc={data.section2.desc}
            titleStyle={{ marginTop: "50px", fontSize: "30px" }}
            descStyle={{ marginTop: "10px", fontSize: "18px" }}
          />
          <div className="normal-media-width">
            <Table
              rowKey="counts"
              columns={columns}
              dataSource={data.section2.items}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: "",
      loading: false
    };
  }

  componentDidMount() {
    ScrollTo();
  }

  validatePhone = (rule, value, callback) => {
    if (value === '') {
      callback();
    } else if (
      !/^1[34578]\d{9}$/.test(value) &&
      !/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)
    ) {
      callback("输入的联系电话无效！");
    } else {
      callback();
    }
    // 固定电话
    // if(!/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)){
    //   callback("输入的联系电话无效！");
    // } else {
    //   callback();
    // }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        if (values.captcha.toUpperCase() !== this.state.captcha.toUpperCase()) {
          this.props.form.setFields({
            captcha: { value: values.captcha, errors: ["error"] }
          });
          notification.error({
            message: "提交失败",
            description: "请输入正确的验证码！"
          });
          this.refs.captcha.createCodes();
        } else {
          this.refs.captcha.createCodes();
          delete values.captcha;
          this.setState({loading: true});
          $.ajax({
            type: "post",
            url: `https://eccqa.weadmin.com/reguserinfo/add`,
            dataType: "json",
            // contentType: 'application/json',
            // data: JSON.stringify(values),
            data: values,
            success: result => {
              if (result && result.code === 0) {
                notification.success({
                  message: "提交成功",
                  description: "感谢您提交申请，30分钟内客服经理会与您联系！"
                });
                this.props.form.resetFields();
              } else {
                notification.error({
                  message: "申请失败",
                  description: "请稍后再试！"
                });
              }
              this.setState({loading: false});
            },
            error: function(err) {
              notification.error({
                message: "申请失败",
                description: "请稍后再试！"
              });
              this.setState({loading: false});
            }
          });
        }
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 6
        }
      }
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="normal-form"
        {...formItemLayout}
        onSubmit={this.handleSubmit}
      >
        <Form.Item label="公司名称">
          {getFieldDecorator("CompanyName", {
            rules: [
              {
                required: true,
                message: "请输入您的公司名称"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="联系人">
          {getFieldDecorator("ContactPerson", {
            rules: [
              {
                required: true,
                message: "请输入联系人"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="联系电话">
          {getFieldDecorator("PhoneNumber", {
            rules: [
              {
                required: true,
                message: "请输入您的联系电话"
              },
              {
                validator: this.validatePhone
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="企业邮箱">
          {getFieldDecorator("Email", {
            rules: [
              {
                type: "email",
                message: "请输入正确的邮箱"
              },
              {
                required: true,
                message: "请输入您的邮箱"
              }
            ]
          })(<Input />)}
        </Form.Item>
        {/* <Form.Item label="下载产品">
          {getFieldDecorator("Product", {
            rules: [
              {
                required: true,
                message: "请选择一个下载产品"
              }
            ]
          })(
            <Select>
              <Select.Option value="ECC">ECC</Select.Option>
              <Select.Option value="ITSM">ITSM</Select.Option>
            </Select>
          )}
        </Form.Item> */}
        <Form.Item label="需求描述">
          {getFieldDecorator("DemandDes", {
            rules: [{ required: true, message: "请输入您的需求描述" }]
          })(<Input.TextArea rows={6} />)}
        </Form.Item>
        <Form.Item label="验证码">
          <Row gutter={8}>
            <Col span={12}>
              {getFieldDecorator("captcha", {
                rules: [{ required: true, message: "请输入右侧验证码" }]
              })(<Input />)}
            </Col>
            <Col span={12}>
              <Captcha
                ref="captcha"
                height="44"
                style={{ border: "1px solid #ddd" }}
                onChange={captcha => this.setState({ captcha })}
              />
            </Col>
          </Row>
        </Form.Item>
        {/* <Form.Item {...tailFormItemLayout}>
                    {getFieldDecorator('agreement', {
                        valuePropName: 'checked',
                    })(
                        <Checkbox>
                            请确认您已阅读我们的<a href="" style={{textDecoration: 'underline'}}>安全须知</a>，我们承诺尊重及保证您的信息安全及隐私，保障您的个人信息权益。
                        </Checkbox>,
                    )}
                </Form.Item> */}
        <Form.Item {...tailFormItemLayout}>
          <Button loading={this.state.loading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrapperForms = Form.create()(Forms);
