import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter  } from 'react-router';
import PropTypes from 'prop-types';
import { Sticky } from 'react-sticky';
import IconFont from '../common/IconFont';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dropdown, Menu, Tooltip, message } from 'antd';

const menuData = [
    { title: '产品中心', desc: '概述与功能', to: '/Product', contains: ['/Product', '/ProductEcc', '/ProductItsm'] },
    { title: '解决方案', desc: '应用不同场景', to: '/Solutions' },
    { title: '快速上手', desc: '用户指南与常见问题', to: '/QuickStart' },
    { title: '在线体验', desc: '在线体验环境与教程', to: '/OnlineExperience' },
    { title: '客户案例', desc: '20000+优质企业用户', to: '/CustomerCase' },
    { title: '关于游龙', desc: '大事记、诚聘英才、联系我们', to: '/About' }
];

class GlobalHeader extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    onMenuItemClick = (item) => {
        this.props.history.push(item.to);
    }
    
    render() {
        const pathname = this.props.location.pathname;
        const overlay = (
            <Menu>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="https://demo.siteview.com">
                        ECC 新界面(beta版本)
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="https://demo.siteview.com/webloader">
                        ECC 老界面
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="https://eccqa.weadmin.com">
                        ITSM 新界面(beta版本)
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="app-header">
                <div className="header-tool">
                    <div className="header-tool-left">
                        商业用户<span className="divider-vertical">|</span>合作伙伴
                    </div>
                    <div className="header-tool-right">
                        <a>
                            <IconFont style={{color: '#1890ff', marginRight: '5px'}} type="sv-dianhua" />
                            <CopyToClipboard text="13520830552" onCopy={(text, result) => {if(result) {message.success('复制成功')}}}>
                                <Tooltip title="点击复制">
                                    <span>13520830552</span>
                                </Tooltip>
                            </CopyToClipboard>
                        </a>
                        <Link to="/Download">软件下载</Link>
                        <Dropdown overlay={overlay} placement="bottomRight">
                            <a className="online-demo-btn">在线演示 <IconFont type="sv-down" /></a>
                        </Dropdown>
                    </div>
                </div>
                <Sticky topOffset={30}>
                    {(props) => {
                        return (
                            <div className="header-content" style={props.style}>
                                <div className="logo" style={{backgroundImage: `url(${require('../../images/logo.png')})`}} onClick={() => {this.props.history.push('')}}></div>
                                <div className="navMenu">
                                    {menuData.map((item, index) => {
                                        let active = '';
                                        if (item.hasOwnProperty('contains')) {
                                            if (item.contains.indexOf(pathname) > -1) {
                                                active = ' active';
                                            }
                                        } else if(pathname === item.to) {
                                            active = ' active';
                                        }
                                        return (
                                            <div className={"navItem" + active} key={index} onClick={()=>this.onMenuItemClick(item)}>
                                                <div className="navItem-title">{item.title}</div>
                                                <div className="navItem-desc">{item.desc}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }}
                </Sticky>
            </div>
        )
    }
}

export default withRouter(GlobalHeader);