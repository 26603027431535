export const CustomerCaseData = [
    //企业
    {
      img: "wdjt.png",
      intro: "#企业案例",
      date: "",
      title: "万达集团实施SITEVIEW ECC保障商业地产互联",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/万达" } },
      path: "wanda",
      type: "企业"
    },
    {
      img: "zgsh.png",
      intro: "#企业案例",
      date: "",
      title: "华东石油公司应用SITEVIEW网管软件智能IT运维",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/中石化" } },
      path: "sinopec",
      type: "企业"
    },
    {
      img: "dfrc.png",
      intro: "#企业案例",
      date: "",
      title: "郑州日产实施SITEVIEW ECC保障核心业务持续高效可用",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/郑州日产" } },
      path: "nissan",
      type: "企业"
    },
    {
      img: "gyqx.png",
      intro: "#企业案例",
      date: "",
      title: "中国医疗器械有限公司通过VMware ESXI监控虚拟机系统，数量规模较大。",
      link: {
        label: "查看案例",
        to: { pathname: "/CustomerCase/中国医疗器械" }
      },
      path: 'ylqx',
      type: "企业"
    },
    {
      img: "ahsslt.png",
      intro: "#企业案例",
      date: "",
      title: "SITEVIEW ECC助淮水北调运营信息化系统健康稳定高可用",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/淮水北调" } },
      path: 'hsbd',
      type: "企业"
    },
    {
      img: "xkjt.png",
      intro: "#企业案例",
      date: "",
      title: "徐矿集团实施SITEVIEW NNM全面自动化网络设备管理",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/徐矿集团" } },
      path: 'xkjt',
      type: "企业"
    },
    {
      img: "scgkwl.png",
      intro: "#企业案例",
      date: "",
      title: "四川广电实施SITEVIEW NNM保障业务运营",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/四川广电" } },
      path: 'scgd',
      type: "企业"
    },
    {
      img: "dyyx.png",
      intro: "#企业案例",
      date: "",
      title: "东方有线多年采购SITEVIEW ECC保障核心业务运营",
      link: { label: "查看案例", to: { pathname: "/CustomerCase/东方有线" } },
      path: 'dfyx',
      type: "企业"
    },
    // 运营商
    {
      img: "hnyd.png",
      intro: "#企业案例",
      date: "",
      title: "河南移动分布式部署实施SITEVIEW ECC一体化IT运维管",
        link: {
          label: "查看案例",
          to: { pathname: "/CustomerCase/河南移动" }
        },
      path: 'hnyd',
      type: "运营商"
    },
    {
        img: "zgyd.png",
        intro: "#企业案例",
        date: "",
        title: "中国移动互联网公司南方基地应用SITEVIEW ECC助力业务运营",
          link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/中国移动互联网公司南方基地" }
          },
        path: 'nfjd',
        type: "运营商"
    },
    {
        img: "zgdxayy.png",
        intro: "#企业案例",
        date: "",
        title: "中国电信爱音乐基地部署SITEVIEW ECC 打造大数据云服务",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/中国电信爱音乐基地" }
            },
        path: 'ayyjd',
        type: "运营商"
    },
    {
        img: "jllt.png",
        intro: "#企业案例",
        date: "",
        title: "吉林联通部署SITEVIEW网管软件助推智慧IT运维",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/吉林联通" }
            },
        path: 'jllt',
        type: "运营商"
    },
    // 教育
    {
        img: "xcjyyx.png",
        intro: "#企业案例",
        date: "",
        title: "北京西城教育研修学院实施SITEVIEW NNM智能网络监测管理运维",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/北京西城教育研修学院" }
            },
        path: 'bjxc',
        type: "教育"
    },
    {
        img: "gjjyzy.png",
        intro: "#企业案例",
        date: "",
        title: "教育部国家资源公共服务平台借SITEVIEW ECC保障公众资源",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/教育部国家资源公共服务平台" }
            },
        path: 'jyb',
        type: "教育"
    },
    {
        img: "nxszslhxy.png",
        intro: "#企业案例",
        date: "",
        title: "宁夏石嘴山联合学院（SITEVIEW NNM）",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/宁夏石嘴山联合学院" }
            },
        path: 'szs',
        type: "教育"
    },
    // 政府
    {
        img: "hnsgat.png",
        intro: "#企业案例",
        date: "",
        title: "湖南省公安厅实施SITEVIEW ECC一体化IT运营支撑系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/湖南省公安厅" }
            },
        path: 'gat',
        type: "政府"
    },
    {
        img: "jlszrzyt.png",
        intro: "#企业案例",
        date: "",
        title: "吉林国土资源厅成功升级SITEVIEW IT运维管理系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/吉林国土资源厅" }
            },
        path: 'zyt',
        type: "政府"
    },
    {
        img: "wzsw.png",
        intro: "#企业案例",
        date: "",
        title: "吴忠市委实施SITEVIEW ECC实现自动化运维",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/吴忠市委" }
            },
        path: 'wzsw',
        type: "政府"
    },
    {
        img: "zhqb.png",
        intro: "#企业案例",
        date: "",
        title: "总后勤部借SITEVIEW ECC智能信息化管理",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/总后勤部" }
            },
        path: 'zhqb',
        type: "政府"
    },
    // 医疗
    {
        img: "bjzyydxzyxy.png",
        intro: "#企业案例",
        date: "",
        title: "北京中医医院升级一体化管理保障健康疗养平台",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/北京中医医院" }
            },
        path: 'zyyy',
        type: "医疗"
    },
    {
        img: "cqdsjydx.png",
        intro: "#企业案例",
        date: "",
        title: "重庆第三军医大学第一附属医院升级SITEVIEW一体化运维管理成功",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/重庆第三军医大学第一附属医院" }
            },
        path: 'dsjy',
        type: "医疗"
    },
    {
        img: "cqplzxyy.png",
        intro: "#企业案例",
        date: "",
        title: "重庆涪陵中心医院升级SITEVIEW网管软件保障信息化医疗系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/重庆涪陵中心医院" }
            },
        path: 'zxyy',
        type: "医疗"
    },
    // 金融
    {
        img: "wxcw.png",
        intro: "#企业案例",
        date: "",
        title: "万向财务有限公司升级SITEVIEW IT运维管理系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/万向财务有限公司" }
            },
        path: 'wxcw',
        type: "金融"
    },
    {
        img: "hsjj.png",
        intro: "#企业案例",
        date: "",
        title: "华商基金升级为ECC保障现代化金融系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/华商基金" }
            },
        path: 'hsjj',
        type: "金融"
    },
    {
        img: "cqnyfzyh.png",
        intro: "#企业案例",
        date: "",
        title: "重庆农发行升级SITEVIEW ECC保障互联网+金融",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/重庆农发行" }
            },
        path: 'nfh',
        type: "金融"
    },
    {
        img: "zggsyhshfh.png",
        intro: "#企业案例",
        date: "",
        title: "中国工商银行上海分行SITEVIEW 综合监测运维管理系统",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/中国工商银行上海分行" }
            },
        path: 'gsyh',
        type: "金融"
    },
    {
        img: "bjszzsrzzx.png",
        intro: "#企业案例",
        date: "",
        title: "北京数字认证股份有限公司 深度日志分析提升CA业务可用性99.99%",
            link: {
            label: "查看案例",
            to: { pathname: "/CustomerCase/北京数字认证股份有限公司" }
            },
        path: 'szrz',
        type: "金融"
    },
];

export const NewsData = [
    { 
        img: 'siteview_ecc.png',
        date: '28 March, 2018', intro: '#客户项目类', 
        title: '中科院成都公司借SITEVIEW ECC 实现一体化IT运维', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/中科院项目' } 
        },
        type: '客户项目类',
        path: 'zky'
    },
    { 
        img: 'xnzf.png',
        date: '12 February, 2018', intro: '#活动类', 
        title: '游龙科技2018新年春节祝福寄语', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/新年春节祝福寄语' } 
        },
        type: '活动类',
        path: 'cjzf'
    },
    { 
        img: 'siteview_ecc.png',
        date: '08 January, 2018', intro: '#客户项目类', 
        title: '湖南省公安厅实施SITEVIEW ECC一体化IT运营支撑系统', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/湖南省公安厅' } 
        },
        type: '客户项目类',
        path: 'gat'
    },
    { 
        img: 'zgxxjs.png',
        date: '21 November, 2017', intro: '#活动类', 
        title: '游龙出席中国信息技术主管大会 创新助力大型企业IT业务群高可运营', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/游龙出席中国信息技术主管大会' } 
        },
        type: '活动类',
        path: 'zgxxjszgdh'
    },
    { 
        img: 'siteview_ecc.png',
        date: '27 September, 2017', intro: '#技术更新通告类', 
        title: 'SITEVIEW ECC V1.2.9发布，近500个创新技术细节精致化', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/SITEVIEW ECC V1.2.9发布' } 
        },
        type: '技术更新通告类',
        path: 'sv-v1.2.9'
    },
    { 
        img: 'wlwgflt.png',
        date: '31 August, 2017', intro: '#活动类', 
        title: '游龙出席全球物联网高峰论坛 大方向与技术创新赋能价值', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/游龙出席全球物联网高峰论坛' } 
        },
        type: '活动类',
        path: 'qqwlwgflt'
    },
    { 
        img: 'siteview_ecc.png',
        date: '10 August, 2017', intro: '#技术更新通告类', 
        title: 'SITEVIEW ECC V1.2.8发布，会“聊天”开创新时代IT运维', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/SITEVIEW ECC V1.2.8发布' } 
        },
        type: '技术更新通告类',
        path: 'sv-v1.2.8'
    },
    { 
        img: 'hlt.png',
        date: '29 June, 2017', intro: '#技术更新通告类', 
        title: '会聊天的IT运维系统 SITEVIEW又领2017网管论坛北京主旋律', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/会聊天的IT运维系统' } 
        },
        type: '技术更新通告类',
        path: 'hlt-zxl'
    },
    { 
        img: 'yczm.png',
        date: '15 May, 2017', intro: '#技术更新通告类', 
        title: '基于HTML5网页远程桌面 企业IT运维集中远程管理黑科技级大功能', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/远程桌面' } 
        },
        type: '技术更新通告类',
        path: 'yczm'
    },
    { 
        img: 'siteview_ecc.png',
        date: '10 May, 2017', intro: '#技术更新通告类', 
        title: 'SITEVIEW ECC V1.2.7发布，基于HTML5网页远程桌面黑科技将成业界亮点', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/SITEVIEW ECC V1.2.7发布' } 
        },
        type: '技术更新通告类',
        path: 'sv-v1.2.7'
    },
    { 
        img: 'siteview_ecc.png',
        date: '08 March, 2017', intro: '#技术更新通告类', 
        title: 'SITEVIEW ECC V1.2.6发布', 
        link: { 
            label: '阅读新闻', 
            to: { pathname: '/About/NewsCenter/SITEVIEW ECC V1.2.6发布' } 
        },
        type: '技术更新通告类',
        path: 'sv-v1.2.6'
    },
];

export const SolutionsData = [
    {
        section1: {
            img: '解决方案-企业运维.jpg',
            title: '企业运维',
            desc: '中小企业运维解决方案',
            desc2: '向企业提供低门槛可快速落地的运维服务管理系统',
            content: '中小企业运维部门，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '随着信息化建设的深入，中小企业运维部门压力越来越大，一方面运维人员比较少，运维人员的工作忙而无果，价值得不到体现； 另外一个方面，运维工作没有规范化、流程化，维护操作随意性强，业务支撑得不到保障。因此中小企业运维部门，急需低门槛可 快速落地的运维服务管理系统，以提高工程师工作效率、规范故障处理流程和呈现服务价值。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 没有统一的信息化服务平台，用户通过多种渠道向工程师报修，工程师压力巨大。对于用户来说，服务过程不清晰，抱怨众多。',
                    '2. 传统的救火式运维服务方式，已不能满足企业内部日趋扩大的信息化应用，而且在一定程度上也制约和影响了业务的开展。',
                    '3. 企业难以形成知识库、案例库，运维人员变动将是致命的动荡，具有极大的风险。',
                    '4. 传统ITSM和大多数的流程系统，太重太复杂，降低了服务效率；对于运维相关的人员、资产、流程采用多套系统管理，导致最终 运维体系无法有效推行。',
                    '5. 故障数据得不到有效分析，无法对服务流程进行优化改进，运维部门的价值也得不到体现。'
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: 'SITEVIEW推荐中小企业采用 SaaS、On-premise SaaS模式合作，具体解决方案如下',
            items: [
                { 
                    title: '服务台升级',
                    content: '建立集中响应的服务台，提供多渠道接入的工单系统，大大降低服务台压力，传统前台就能升级为服务台。'
                },
                { 
                    title: '统一运维',
                    content: '统一的运维管理平台，智能化的工单分派和流转，摆脱救活式的运维局面，轻重缓急心中有数。'
                },
                { 
                    title: '移动化处理',
                    content: '移动化的处理方式，提供微信小程序、APP等手机应用，工程师随时随地都可以接单和处理。'
                },
                { 
                    title: '采用敏态工单',
                    content: '采用敏态工单，可跨部门抄送，快速协同处理问题，并可以关联客户、设备、项目，大大提升工程师服务效率。'
                },
                { 
                    title: '快速建立知识库',
                    content: '日常工单一键转化为知识文章，形成企业知识库。可供终端用户自助参考；也可方便工程师处理工单时自动关联提供解决方案参考。'
                },
                { 
                    title: '多维度报表',
                    content: '提供多维度服务报表，呈现运维工作价值。'
                },
            ]
        },
        section4: {
            title: '方案优点',
            items: [
                { icon: 'sv-plus-monitored', content: '服务管理可视化' },
                { icon: 'sv-xianshiqi', content: '工作结果可视化' },
                { icon: 'sv-fenjianguocheng_o', content: '运维工作可视化' },
                { icon: 'sv-guochengfenxi', content: '服务过程可视化' },
                { icon: 'sv-good', content: '客户满意度提升' },
                { icon: 'sv-jiangdichengben', content: '服务成本降低' },
            ],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-IT运营商.jpg',
            title: 'IT运营商',
            desc: 'IT服务外包商解决方案',
            desc2: '向IT外包商提供低门槛可快速落地的运维服务管理系统',
            content: 'IT运营商，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '当下，从成本、效率、收益等方面考虑，更多的中小型企业会选择专业的IT外包服务商来运维IT。越来越多的IT企业转型进入外包服务这片“红海”，IT服务同行业竞争被无限放大。在互联网+时代，IT服务商急需通过服务变革，解决服务难题，提升服务效率和质量，提升业务竞争力！'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 如何统筹智能的协调和分派任务，提升服务效率，降低成本？',
                    '2. 如何评估和管控每位工程师的工作量和服务质量？ ',
                    '3. 如何解决工程师流动大，执行流程不规范，风险高？',
                    '4. 如何管理备品备件，以及相关联的客户、事件、工程师？',
                    '5. 如何快速为客户提供详细准确的服务报表，以提升满意度和客户黏度？'
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: '',
            items: [
                { 
                    title: '服务台升级',
                    content: '建立集中响应的服务台，提供多渠道接入的工单系统，大大降低服务台压力，传统前台就能升级为服务台。'
                },
                { 
                    title: '统一运维',
                    content: '提供多渠道的报障模式，选择用户最常用的渠道嵌入报障入口，用户随时随地一键报修，自动收取处理进度通知。 对于IT服务中对资产的管理难题，可为每个设备创建一个二维码，用户扫描设备二维码可以查看设备信息、使用指南、一键报修、查看维修记录等'
                },
                { 
                    title: '移动化处理',
                    content: '移动化的处理方式 提供完善的移动端解决方案，包括微信小程序、APP等，便捷接收、查询和处理任务；抛弃传统的纸质工单，对客户、服务台人员、处理人、管理人员实时信息共享，协同高效。可以建立多级支持部门，实现服务台快速响应，一线现场解决，二线专业支持，三线专家评估，跨部门跨组织无缝沟通快速解决问题'
                },
                { 
                    title: '全流程管控',
                    content: '提供流程管控工具ITIL全流程管控能力，用户可以通过服务目录提交相应的流程工单，服务人员也可以把普通报障单一键转为流程，分析问题根源彻底解决故障采用敏态工单，可跨部门抄送，快速协同处理问题，并可以关联客户、设备、项目，大大提升工程师服务效率。'
                },
                { 
                    title: '健全的服务质量管理',
                    content: '建立健全的服务质量管理体系 系统提供SLA、OLA严格管理服务流程各节点响应时间和处理时长，以及各个流程的异常通知警告，保证服务质量，提升客户满意度'
                },
                { 
                    title: '知识库',
                    content: '建立强大的知识库系统'
                },
                { 
                    title: '资产管理库',
                    content: '建立资产管理库提供包括资产信息管理和配置关联管理。清晰管理资产的软硬件信息，以及在组织网络中的拓扑关系，以实现资源合理配置，并为决策提供依据。 对每个资产设备可以生成二维码，用户扫码自动识别设备一键发起报修。'
                },
                { 
                    title: '服务报告',
                    content: '服务报告 系统默认提供多维度的服务报表，对用户的报修情况、服务评价；对工程师和团队的服务任务、达标率、服务质量进行全面的分析，一键生成服务报告，呈现服务价值，为优化服务和流程提供有力的数据支撑'
                },
            ]
        },
        section4: {
            title: '方案优点',
            items: [
                { icon: '', content: '一体化管理' },
                { icon: '', content: '配置灵活' },
                { icon: '', content: '工单报障移动化' },
                { icon: '', content: '流程可追踪' },
                { icon: '', content: '多项SLA监控机制' },
            ],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-企业业务协同.jpg',
            title: '企业业务协同',
            desc: '企业业务协同解决方案',
            desc2: '向企业提供跨部门/跨企业的可视化协同方案',
            content: '企业业务协同，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '随着信息化建设的深入，中小企业运维部门压力越来越大，一方面运维人员比较少，运维人员的工作忙而无果，价值得不到体现； 另外一个方面，运维工作没有规范化、流程化，维护操作随意性强，业务支撑得不到保障。因此中小企业运维部门，急需低门槛可 快速落地的运维服务管理系统，以提高工程师工作效率、规范故障处理流程和呈现服务价值。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 多部门流转繁琐，解决客户问题效率低下',
                    '2. 产品复杂+更新频繁，服务人员素求及培训要求较高',
                    '3. 服务团队难管理，工作难量化，绩效难考核',
                    '4. 企业产品多样，需要更精准的知识库管理系统',
                    '5. 客户问题反馈渠道单一，响应速度慢'
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: 'SITEVIEW ITOSS 是集移动化、智能化、云端化于一身的标准化IT服务管理平台，不仅能让多个部门全局掌握服务过程、结果与质量，便捷管理人员、事件、资源，还能搭建服务多方信息桥梁，增强各部门的沟通效率，提升业务服务效率和价值。 ',
            items: [
                { 
                    title: '工单系统',
                    content: '独创的双模工单系统将完美解决用户需求问题复杂、内部配合协作的企业难题。无缝流转灵活指派，实现跨部门高效协同。'
                },
                { 
                    title: '实时提醒',
                    content: '提供实时提醒功能，和超时告警功能。新工单到达会收到即时提醒；如果没有及时响应，系统会产生告警通知管理人员。'
                },
                { 
                    title: '自定义问题分类',
                    content: '支持自定义问题分类，并将不同的类型问题通过触发器自动流转到指定部门处理，有序地组织起整个企业服务的公司内部协同，极大提高了客户问题解决的效率。'
                },
                { 
                    title: '知识库',
                    content: '提供知识库模块，通过日常工单一键生成知识文章，为企业建立完整的知识体系。'
                },
                { 
                    title: '智能分配',
                    content: '多渠道接入、一键流转；将来自各种社交渠道的信息都统一到工单，智能分配给部门员工，只需一键流转，便可跨部门将工单转移到其他部门。'
                },
                { 
                    title: '工作效率',
                    content: 'SLA监控，提高工作效率；事有轻重缓急，利用工单系统，保障重要的事情优先处理，提高企业运营质量，确保事件的处理效率。'
                },
                { 
                    title: '轻量化的CRM系统',
                    content: '轻量化的CRM系统，在线咨询、呼叫中心的用户可直接通过userID匹配ServiceHot 的用户信息，自动识别客户，快速管理客户信息。'
                },
            ]
        },
        section4: {
            title: '',
            items: [],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-现场服务.jpg',
            title: '现场服务',
            desc: '现场服务解决方案',
            desc2: '向企业提供互联网化服务管理解决方案',
            content: '现场服务，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '随着互联网的发展，生活服务已覆盖了日常生活的各种场景，线上线下结合的现场服务顺势拥有了巨大市场，他们都需要线下为客户交付服务，这类企业多是中小型企业，做好客户服务是提高业务增长的关键手段。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 客户对服务不满，导致业务开展受挫',
                    '2. 处理效率不高，使本来有限的资源更加捉襟见肘',
                    '3. 外派人员难管理，服务成本增高却不透明',
                    '4. 碎片化管理，难以形成长效机制及数据积累',
                    '5. 现场服务涉及提单、派单、实施、结单和客户调查等多个环节，如何确保服务质量，提高效率，降低成本，是每一位服务管理人员面临的挑战',
                    '6. 严明的规章制度和公平的绩效考核规则固然必要，难点依然在于如何将服务流程落实到位，并取得服务人员实际的工作数量和质量数据'
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: '',
            items: [
                { 
                    title: '互联网化',
                    content: 'SITEVIEW ITOSS 为企业提供一整套互联网化服务管理的解决方案，通过移动端和Web端访问，可以连接客户和现场人员，客户可以通过它查看产品信息，自助提交服务请求，了解处理进度。'
                },
                { 
                    title: '移动化处理',
                    content: '服务人员可以通过移动端接收、分派现场任务，并按照流程执行服务内容，还可以查询产品、配件、工具等信息，完成服务后通过定位、拍照、客户签名等方式，反馈服务结果。'
                },
                { 
                    title: '工单系统',
                    content: '通过工单系统把客户、客服人员（调度）和服务工程师串联在一起，工单相当于即时聊天讨论组，大大提高了三方沟通的效率。'
                },
                { 
                    title: '客服中心',
                    content: '客服中心实时掌握现场和工程师的状态，调度员就心中有数，派单也更准确合理。客户的参与和积极反馈，改善了现场服务的透明度，客观上起到了对服务人员的监督作用。'
                },
            ]
        },
        section4: {
            title: '方案优点',
            items: [
                { icon: '', content: 'SITEVIEW ITOSS是行业内唯一可以提供全渠道创建工单的现场服务系统。' },
                { icon: '', content: '真正的全流程、自定义、智能化、数据化的现场服务管理系统。' },
                { icon: '', content: '为外派人员、现场服务人员量身打造的移动系统。' },
            ],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-客户服务.jpg',
            title: '客户服务',
            desc: '软件厂商客户服务解决方案',
            desc2: '向软件厂商提供互联网化服务管理解决方案',
            content: '软件厂商，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '在互联网时代，软件企业有两个关键的发展方向，一是通过技术提高产品核心竞争力；二是通过透明化的优质服务方式留住客户。软件厂商发展到一定的规模，除了自身客户服务体系的建设，还要考虑对分支部门对代理商分销商的技术支撑。而且，代理商的服务质量也是厂商非常关注的问题，如何更加快速响应客户的需求、服务好客户，管理好代理商成为当下所有软件企业急需解决的难题。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 软件厂商客服中心承担了大部分的售前技术支持工作，由于软件产品的属性，会经常收到产品演示的请求，一般都只能通过QQ向客户进行远程产品演示，服务信息分散，难以形成统一的服务管理和跟进，客户信息碎片化，服务效率一度跟不上业务发展速度',
                    '2. 服务网络已经遍布全国，区域级、本地化、标准化的服务更多是授权给全国各地多达几百上千家有服务实力的代理商，如何调动他们的服务意识、提升他们的服务能力和管理整个服务体系，已经成为软件厂商当前最重要最紧迫需要解决的问题',
                    '3. 服务效率决定企业整个服务团队的效能和价值广度，而服务质量则直接关系到整个团队的使能能力及价值深，如何设计一套服务质量控制体系？',
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: '',
            items: [
                { 
                    title: '统一服务管理',
                    content: '将现有渠道全部接入平台，统一的响应和管理，客服通过工单即可与客户进行实时沟通，而对于一级客服解决不了的问题，则直接将工单，原汁原味分派给其它部门进行协同处理'
                },
                { 
                    title: '强大的知识库提供有力支持',
                    content: '将客户常见的问题编制成知识库文档供客户自助查询，在处理工单过程中客服人员可以快速查阅相关知识文章，快速解决客户问题。'
                },
                { 
                    title: '厂商、代理商高效协同',
                    content: 'SITEVIEW ITOSS 可将代理商的渠道统一到平台，通过分组/权限等设置，与厂商团队组成遍布全国客户服务网，在同一平台协同服务。 '
                },
                { 
                    title: '把控服务质量，确保用户满足',
                    content: '厂商可以监测所有代理商客服的在线状态、查看所有数据信息，当遇到代理商处理客户问题不及时、严重问题时，也可以强制转接给二线支持组，大大提升了整体的服务能力和质量。'
                },
                { 
                    title: '多维度统计报表，验证服务质量',
                    content: 'SITEVIEW ITOSS 数据报表，让企业更加了解自己的团队和客户，对团队的工作业绩也有了客观真实的依据。'
                },
            ]
        },
        section4: {
            title: '',
            items: [],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '首页-ITSM.jpg',
            title: '连锁IT服务',
            desc: '连锁行业IT运营解决方案',
            desc2: '向连锁企业提供 “简单高效” 的 IT 服务管理体系',
            content: '连锁企业，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '要做好连锁零售行业IT管理，将面临很多挑战：IT业务需求变化快、IT工作负荷大；IT服务显性比高、IT环境复杂，覆盖各种各样的IT资源、大量基础设施和复合应用系统；IT组织需要严谨细致的管理和监管，并要求不断提高投资回报率；需要将分散在不同系统的信息集成共享等等。而IT运维服务管理体系是IT部门工作的重中之重，连锁零售行业IT部门在运维工作中同样面临着很多困难与问题。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 维护区域范围大、散、多，信息资源分散，缺乏对信息资源的统一管理和监控',
                    '2. 处于被动救火状态，遇到突发情况，不能快速解决问题、无法建立应对预案；简单、重复性工作劳动较多，问题没有记录', 
                    '3. 采用一些传统的方式记录工作，或单单依靠各自的记忆力来记录工作，亦或没有任何的工作记录统计，有的只是封存起来的工作或申请单',
                    '4. IT资产状态不明确、IT资产管理混乱、资产关联关系未知、资产无法跟踪管理，版本控制困难、业务支撑能力量化不准确、不及时',
                    '5. 很难对发生的故障进行统计和分析，服务资源不足、无法支撑业务的快速扩张，无数据、报表积累，领导决策无详实数据支撑，常规指标、IT服务价值无法直观、量化体现，流程、制度执行情况无考量，周期性报告编写困难、数据不准确',
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: '',
            items: [
                { 
                    title: '统一服务管理',
                    content: '连锁零售行业的行业特性，决定了连锁企业的门店、维护范围非常广泛，而且都具有一定的区域特征，数量分布也是成百上千，那么面对如此大规模的产业系统，我们的IT运维信息资源如何进行统一的监管，成了IT部门首要解决的问题，必须对所有IT信息资源进行统一收集、管理，建立统一的服务接口'
                },
                { 
                    title: '提高稳定性和可用性',
                    content: 'SITEVIEW ITOSS 通过企业整体框架体系的设计，将建立符合企业本身的统一服务台，快速收集、解决所有门店或区域的IT服务请求及IT事件，保持业务系统、基础设施等等的稳定性和可用性'
                },
                { 
                    title: '信息集中式管理模式',
                    content: '使用SITEVIEW ITOSS独有的“信息集中式管理模式”，实现信息资源的统一监管以及事件管理的流程化、规范化的高效管理，并结合服务目录、级别、IT服务对象级别，建立连锁零售企业“总部集中管控，各分部又逻辑上独立运行”的高效运维模式，满足不同阶段灵活的业务需求，并解决门店极速扩张形成的IT管理困难'
                },
                { 
                    title: 'ITIL的理念',
                    content: '基于ITIL的理念并依据行业实施经验在SITEVIEW ITOSS上，通过识别和管理事件的根本原因，以减少或避免业务系统受到中断；严格记录问题管理整个生命周期，并进行问题汇总分析，实现问题处理共享，极大程度的杜绝简单、重复性工作劳动。解决问题无记录、无法快速解决问题、无应对解决方法等弊端，满足高要求的业务IT服务需求'
                },
                { 
                    title: 'IT数据报表',
                    content: 'IT数据报表作为连锁零售行业在IT生产环境里所有服务参数、指标的数据集、分析趋势集，是连锁零售行业IT运维服务管理中重要的组成部分'
                },
                { 
                    title: '数据分析、流程优化',
                    content: '针对连锁零售行业IT运维管理上一系列的问题和困境，可通过SITEVIEW ITOSS独有的IT管理思想和经验以及连锁零售企业自身的战略和管理目标，进行ServiceHot项目的建设。为企业提供数据分析、流程优化等服务，以此来提升连锁零售企业IT资源的集中利用、降低企业经营管理成本、提高各项业务工作的效率，提升企业的核心竞争力。最终建立起科学规范的、专业的、最适合连锁零售企业的ITIL最佳实践、以及“简单高效”的 IT 服务管理体系'
                },
            ]
        },
        section4: {
            title: '方案优点',
            items: [
                { icon: '', content: '快速构建可持续改善的ITIL管理体系' },
                { icon: '', content: '服务台，多种渠道报障方便快捷' },
                { icon: '', content: '日积月累，知识管理，共享企业文化' },
                { icon: '', content: '协同体系人性化，降低运维成本' },
                { icon: '', content: '灵活丰富的自定义，参数配置，满足实施需求' },
                { icon: '', content: '方便系统集成和接口集成，可实现管控一体化' },
                { icon: '', content: '所有IT软硬件资产配置变化可追溯可审计' },
            ],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-数据中心运维.jpg',
            title: '数据中心运维',
            desc: '数据中心运维解决方案',
            desc2: '根据企业信息化管理成熟度模型，提供一体化运维解决方案',
            content: '数据中心运维，急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: '客户案例',
                contents: [
                    '背景：随着信息化的发展，越来越多的行业借助IT支撑自己的核心业务发挥竞争优势,实现企业数字化转型。如何保障高可用性、高性能的IT服务？如何及时、全面地掌控IT运营的整体状况？如何将个人经验有效的转化为团队知识与能力？如何衡量IT运营价值与质量？这些问题是运维部门和数据中心管理者面临的艰巨挑战。'
                ]
            },
            {
                title: '方案挑战',
                contents: [
                    '1. 没有统一的信息化系统，运维工作在多个系统中流转和管理',
                    '2. 低效率的信息传递渠道', 
                    '3. 日趋复杂的IT系统和有限的IT服务人员之间的矛盾',
                    '4. 个人经验无法有效转化为企业知识',
                    '5. IT服务管理部门主管无法及时、全面的了解技术支持工作的整体状况',
                    '6. 设备和软件供应商众多，需要用IT技术手段管理',
                    '7. 设备和软件的配置信息不详，或者配置信息经过长期维修调整已经与实际不相符合',
                    '8. 缺乏对IT服务人员的绩效考核依据',
                    '9. 缺乏对用户的服务报告',
                ]
            }
        ],
        section3: {
            title: '解决方案',
            desc: '',
            items: [
                { 
                    title: '多渠道接入方式',
                    content: '提供多渠道接入方式，强化服务台集中支持的能力，快速解决故障提高效率'
                },
                { 
                    title: '敏捷协同的客服工单',
                    content: '提供敏捷协同的客服工单功能，普通的请求产生工单、快速流转、协同处理，遇到疑难问题、严重问题可以一键升级为ITIL流程，加强管控避免风险，与配置资产、项目合同等相关信息挂钩'
                },
                { 
                    title: '一体化管理',
                    content: '工单和ITIL流程处理过程中，可通过流程图了解流转轨迹，一体化管理企业复杂的业务流程'
                },
                { 
                    title: '事件管理流程',
                    content: '提供事件管理流程，以闭环的事件管理为基础，快速有效地响应用户的请求，迅速恢复业务的运行，减小对业务的影响'
                },
                { 
                    title: '问题管理流程',
                    content: '查明事件的根本原因，在事件发生之前制定解决方案，使突发事件和问题对业务所造成的影响减少到最小'
                },
                { 
                    title: '变更发布流程',
                    content: '可灵活支持建立独立的变更、发布流程，或将变更发布流程合并成一个流程执行,确保在变更/发布实施过程中使用标准的方法和步骤，尽快地实施变更/发布，流程执行完毕，将自动更新配置信息，保证做到账实相符，将由变更对业务的影响减小到最低'
                },
                { 
                    title: '服务级别管理',
                    content: '服务级别管理保证IT服务水平达到要求，提高服务水平和运维效率'
                },
                { 
                    title: '知识库',
                    content: '引入了国际通用的WIKI平台，可设计自己的知识发布流程，为处理人提供技术支撑'
                },
                { 
                    title: '配置管理',
                    content: '配置管理'
                },
                { 
                    title: '系统管理',
                    content: '支持集团企业组织结构，实现用户及授权管理，并与流程设计工具实现部门和用户资料共享。以树状层次结构显示上下级从属关系，可指定部门排序，支持领导排名，指定部门主管和部门管理员，部门管理员可以维护本部门下属的组织结构和用户权限。 部门管理，树状结构管理企业组织机构，不限部门层级数'
                },
            ]
        },
        section4: {
            title: '方案优点',
            items: [
                { icon: '', content: '快速构建可持续改善的ITIL管理体系' },
                { icon: '', content: '服务台，多种渠道报障方便快捷' },
                { icon: '', content: 'ITIL流程、服务生命周期化，提高运维绩效' },
                { icon: '', content: '建立知识体系，共享企业文化' },
                { icon: '', content: '协同体系人性化，降低运维成本' },
                { icon: '', content: '灵活丰富的自定义，参数配置，满足实施需求' },
                { icon: '', content: '方便系统集成和接口集成，可实现管控一体化' },
                { icon: '', content: '所有IT软硬件资产配置变化可追溯可审计' },
            ],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    },
    {
        section1: {
            img: '解决方案-ITSS工具.jpg',
            title: '一体化运维',
            desc: 'SITEVIEW ITOSS 一体化运维',
            desc2: '基于OSGi动态组件技术，六大模块无缝集成一体，大幅提升IT管理的效率。',
            content: '急需低门槛可快速落地的运维服务管理系统，以提 高工程师工作效率、规范故障处理流程和呈现服务价值。',
            btns: [
                { text: '获取解决方案', to: '/GetSolutions' }
            ]
        },
        section2: [
            {
                title: 'SITEVIEW ITOSS 一体化运维',
                contents: [
                    '基于OSGi动态组件技术，六大模块无缝集成一体，大幅提升IT管理的效率。',
                    '监测：实现对基础设备、网络性能等各类IT基础设施的监测，同时需要构建应用性能监测。',
                    '管理：实现对IT资产的精细化管理；遵循ITIL流程，实现IT运维的规范化流程管理。',
                    '控制：实现对各类IT资源、各类应用自动化管理和智能化控制。'
                ]
            },
            {
                title: '',
                contents: []
            }
        ],
        section3: {
            title: '故障全生命周期管理',
            desc: '从全面监测、智能告警、快速定责、精确定位、规范变更、运营评估等六个维度，协助企业信息化管理部门建立一套应用运维全生命周期管理体系。',
            items: [
                { 
                    title: '全面监测',
                    content: '采用多款监控产品，将从基础设施运行状态、用户体验等多个视角，监测应用系统的可用性、健康度和性能，监测指标覆盖信息中心的各类主机、网络、数据库、中间件和上层应用，实时监测系统用户体验和性能'
                },
                { 
                    title: '智能告警',
                    content: '采用动态基线等智能化预警技术，第一时间通过短信、微信、邮件、工单等方式将故障内容快速、准确通知到责任人'
                },
                { 
                    title: '快速定责',
                    content: '针对应用系统中断、缓慢等故障，通过应用感知型网络流量监控产品NI，通过分析网络流量数据包，从网络、服务器、应用等多个视角对应用故障进行分析和定位，对故障点进行快速判断和定责'
                },
                { 
                    title: '精确定位',
                    content: '采用代码级应用服务端监控产品AI、日志分析产品Li、应用感知型网络流量监控产品NI，对应用系统关联的程序代码、网络性能和状态、系统运行日志进行深入分析，精确定位应用系统故障点'
                },
                { 
                    title: '规范变更',
                    content: '参照ITIL实践，通过Servicehot ITSM服务流程产品建立规范的变更管理流程，通过各类紧急变更、常规变更，确保IT设备、IT应用变更的合规性，降低变更影响风险'
                },
                { 
                    title: '运营评估',
                    content: '充分挖掘运维数据的可用性，建立一套多数据源、多用户视角、沉淀专家经验的运营评估管理平台，通过各个维度的分析报表，建立面向客户感知的、面向业务体验的、面向运行质量，围绕主题的（性能、故障、考核）、多维度（物理资源、虚拟资源、应用性能）的运营分析能力，主动分析系统故障、性能，分析问题、评估瓶颈，为“决策层、管理层、执行层”，提供决策支持'
                },
            ]
        },
        section4: {
            title: '',
            items: [],
            btns: [ { text: '获取解决方案', to: '/GetSolutions' } ]
        }
    }
];