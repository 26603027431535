import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class BlockCard extends Component {
    render() {
        const { img, title, date, intro, link, imgHeight, shadow } = this.props;
        return (
            <div className={"blockCard" + (shadow ? " shadow" : "")}>
                <div className="blockCard-container">
                    <div style={{height: imgHeight || '180px'}}><img src={require(`../../images/${img}`)} alt=""/></div>
                    <div className="block-content">
                        <div>
                            {date ? <div className="date-text">{date}</div> : null}
                            {intro ? <div className="intro-text">{intro}</div> : null}
                            {title ? <div className={`title${(date || intro) ? ' second-title' : ''}`}>{title}</div> : null}
                        </div>
                        <div>
                            {link ? (
                                <Link className="link" to={link.to}>{link.label}</Link>
                            ):null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
