import React, { Component } from "react";
import IconFont from "../components/common/IconFont";
import BlockCard from "../components/common/BlockCard";
import GlobalBreadcrumb from "../components/common/GlobalBreadcrumb";
import GlobalMarkdown from "../markdown/GlobalMarkdown";
import { ScrollTo } from "../script/Utils";
import { CustomerCaseData } from '../script/MockDatas';
import { Row, Col, Carousel, notification, Button } from "antd";

const data = {
  items: CustomerCaseData
};

export default class CustomerCaseSubView extends Component {

  constructor(props) {
      super(props);
      this.state = {
          others: []
      };
  }

  componentDidMount() {
    ScrollTo();
    let item = data.items.find(d => d.link.to.pathname === this.props.location.pathname);
    this.setState({others: data.items.filter(d => d.type === item.type)});
  }

  componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        ScrollTo();
        let item = data.items.find(d => d.link.to.pathname === this.props.location.pathname);
        this.setState({others: data.items.filter(d => d.type === item.type)});
      }
  }

  getItemIndex = () => {
    return data.items.findIndex(d => d.link.to.pathname === this.props.location.pathname);
  };

  next = () => {
    let index = this.getItemIndex();
    if (index < data.items.length - 1) {
      this.props.history.push(data.items[index + 1].link.to.pathname);
    } else {
      notification.info({
         message: '提示',
        description: '已经是最后一个了'
      });
    }
  };

  prev = () => {
    let index = this.getItemIndex();
    if (index > 0) {
      this.props.history.push(data.items[index - 1].link.to.pathname);
    } else {
      notification.info({
        message: '提示',
        description: '已经是第一个了'
      });
    }
  };

  render() {
    const itemIndex = this.getItemIndex();
    const currentItem = data.items[itemIndex];
    const prevTitle = itemIndex === 0 ? "" : data.items[itemIndex - 1].title;
    const nextTitle = itemIndex === data.items.length - 1 ? '' : data.items[itemIndex + 1].title;
    let carouselItems = [];
    for (let i = 0; i < this.state.others.length; i += 3) {
      carouselItems.push(this.state.others.slice(i, i + 3));
    }
    return (
      <div className="newsSubView">
        <div className="breadcrumb-wrapper">
          <GlobalBreadcrumb {...this.props} />
        </div>
        <div className="normal-media-padding news-content">
          {data.items.length ? (
            <div className="pv-btn-wrapper">
                    <div>
                        <Button className="btn-prev" type="link" onClick={this.prev} disabled={itemIndex === 0}><IconFont type="sv-uparrow" />上一篇</Button>
                        <div className="pn-desc">{prevTitle}</div>
                    </div>
                    <div>
                        <Button className="btn-next" type="link" onClick={this.next} disabled={itemIndex === (data.items.length - 1)}>下一篇<IconFont type="sv-uparrow-copy" /></Button>
                        <div className="pn-desc">{nextTitle}</div>
                    </div>
                </div>
            ) : null}
          {currentItem ? (
            <div>
              <div className="news-sub-title" style={{ paddingTop: "53px" }}>
                {currentItem.title}
              </div>
              <div className="news-sub-date">
                {currentItem.date}&nbsp;&nbsp;{currentItem.intro}
              </div>
              <GlobalMarkdown
                markdown={require(`../markdown/CustomerCase/${
                  currentItem.path
                }/index.md`)}
                imgPath={`./CustomerCase/${currentItem.path}/`}
              />
            </div>
          ) : (
            <div>没找到对客户案例信息，请重试！</div>
          )}
          {data.items.length ? (
            <div className="pv-btn-wrapper">
                    <div>
                        <Button className="btn-prev" type="link" onClick={this.prev} disabled={itemIndex === 0}><IconFont type="sv-uparrow" />上一篇</Button>
                        <div className="pn-desc">{prevTitle}</div>
                    </div>
                    <div>
                        <Button className="btn-next" type="link" onClick={this.next} disabled={itemIndex === (data.items.length - 1)}>下一篇<IconFont type="sv-uparrow-copy" /></Button>
                        <div className="pn-desc">{nextTitle}</div>
                    </div>
                </div>
            ) : null}
        </div>
        <div className="news-other-content">
          <div
            className="normal-section-title"
            style={{ textAlign: "left", padding: "0 6.2%" }}
          >
            其他{currentItem.type}客户案例
          </div>
          <div className="carousel-wrapper">
            <Carousel autoplay>
              {carouselItems.map((item, index) => {
                return (
                  <div key={index} className="carousel-item">
                    <Row
                      gutter={19}
                      type="flex"
                      justify="center"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      {item.map((p, iIndex) => (
                        <Col key={iIndex} span={7}>
                          <div style={{ background: "#fff", height: "100%" }}>
                            <BlockCard {...p} imgHeight="276px" />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
