import React, { Component } from 'react'
import WrapperButton from '../components/common/WrapperButton';
import ImgCard from '../components/common/ImgCard';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import $ from 'jquery';
import { ScrollTo } from '../script/Utils';
import { Row, Col, Carousel } from 'antd';

const data = {
    section1: {
        title: '一体化IT监测运维管理',
        desc: 'SITEVIEW ITOSS是现代化IT服务管理平台，选用合适的SITEVIEW ITOSS解决方案将满足您今天和明天的需求。',
        img: '首页-banner.jpg',
        btn: {
            text: '免费云试用版',
            to: "/OnlineExperience"
        },
        btn2: {
            text: '预约演示',
            to: '/Subscribe'
        }
    },
    section2: {
        btn: { text: '获取解决方案', to: '/GetSolutions' },
        items: [
            { 
                img: '首页-ECC.jpg',
                title: 'IT基础设施监控', 
                tip: ' (非代理式)',
                content: '专注对局域网、广域网和互联网上的服务器网络设备、业务应用系统等性能监测，实现秒故障快速发现与定位故障，确保企业关键IT系统、核心业务应用高稳定、高可用。',
                btn: {
                    text: '查看更多',
                    to: '/ProductEcc'
                }
            },
            { 
                img: '首页-ITSM.jpg',
                title: 'IT服务管理', 
                tip: '',
                content: '以ITIL最佳实践为基础，满足ISO20000，COBIT等标准，基于工单的流程化标准管理，故障处理跟踪分析、运营效益考核分析等易用的IT服务管理系统。',
                btn: {
                    text: '查看更多',
                    to: '/ProductItsm'
                }
            }
        ]
    },
    section3: {
        title: '支持多厂商的设备、多IT资源的监测',
        desc: '可以监测市面上90%以上的IT资源监',
        items: ['cisco.png', 'oracle.png', 'huawei.png', 'ibm.png', 'hp.png','apache.png', 'Bea.png', 'EMC.png', 'H3C.png', 'tomcat.png'],
        btn: {
            text: '免费下载试用',
            to: '/Download'
        }
    },
    section4: {
        title: '联系我们',
        secondTitle: '期待与您的合作',
        desc: '欢迎您来电咨询，我们的专家随时准备着帮助您选择最合适您需求的解决方案和价格，马上call我们吧。',
        contact: {
            number: '+86 - 13520830552',
            name: '张经理'
        }
    }
};

let isInit = false;

export default class Home extends Component {

    componentDidMount() {
        ScrollTo();
        window.onscroll = this.onScrollChangeHandler;
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    onScrollChangeHandler = () => {
        var top = document.documentElement.scrollTop || document.body.scrollTop;
        if (top <= 400) {
            if (top <= 200) {
                let _scale = 1 + top*0.0025;
                $('.home-section1 .img-wrapper').css('transform', `scale3d(${_scale}, ${_scale}, 1)`);
            }
            let evenTop = top * 0.4;
            $('.home-section2-item.even').css('transform', `translate3d(0, ${150 - (evenTop > 150 ? 150 : evenTop)}px, 0)`);
            isInit = false;
        } else {
            if (!isInit) {
                $('.home-section1-img').css('transform', `scale3d(1.5, 1.5, 1)`);
                $('.home-section2-item.even').css('transform', `translate3d(0, 0, 0)`);
                isInit = true;
            }
        }
    }

    render() {
        let carouselItems = [];
        for (let i = 0; i < data.section3.items.length; i += 5) {
            carouselItems.push(data.section3.items.slice(i, i + 5));
        }
        return (
            <div className="homeView">
                <div className="home-section1-section2-wrapper">
                    <div className="home-section1">
                        <MainSectionDisplay 
                            img={data.section1.img} 
                            title={data.section1.title} 
                            desc={data.section1.desc} 
                            btns={[data.section1.btn, data.section1.btn2]} 
                            height={600}
                            titleStyle={{marginTop: '85px', fontSize: '50px'}}
                            btnType="transparent"
                        />
                    </div>
                    <div className="home-section2">
                        <Row gutter={{md: 0, lg: 45}} type="flex">
                            {data.section2.items.map((item, index) => {
                                return (
                                    <Col key={index} md={24} lg={12} style={{marginBottom: '45px'}}>
                                        <div className={"home-section2-item" + (index % 2 === 0 ? " odd" : " even")}>
                                            <ImgCard {...item} />
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                        <div style={{textAlign: 'center'}}>
                            <WrapperButton className="linearBorderBtn" style={{width: '221px'}} btn={data.section2.btn}>{data.section2.btn.text}</WrapperButton>
                        </div>
                    </div>
                </div>
                <div className="home-section3">
                    <div className="normal-section-title">{data.section3.title}</div>
                    <div className="normal-section-desc">{data.section3.desc}</div>
                    <div className="carousel-wrapper">
                        <Carousel autoplay>
                            {carouselItems.map((item, index) => {
                                return (
                                    <div key={index} className="carousel-item">
                                        <Row  type="flex" justify="center">
                                            {item.map((img, iIndex) => (
                                                <Col key={iIndex} xl={4} xxl={3} >
                                                    <img src={require(`../images/${img}`)} alt=""/>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div style={{textAlign: 'center', marginTop: '30px'}}>
                        <WrapperButton className="linearBorderBtn" style={{width: '221px'}} btn={data.section3.btn}>{data.section3.btn.text}</WrapperButton>
                    </div>
                </div>
            </div>
        )
    }
}
