import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconFont from '../components/common/IconFont';
import WrapperButton from '../components/common/WrapperButton';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import ImgCard from '../components/common/ImgCard';
import { ScrollTo } from '../script/Utils';
import { Row, Col, Carousel, Table, Tabs } from 'antd';
const { TabPane } = Tabs;

const data = {
    title: 'SITEVIEW IT服务管理',
    tabs: [
        {
            title: '概述',
            section1: {
                title: 'SITEVIEW IT 服务管理',
                desc: '规范化管理流程、高可配置实现100%功能，避免“削足适履”，实现IT系统的安全运行',
                img: '解决方案-企业业务协同.jpg',
                btns: [
                    { text: '免费云试用版', to: "https://eccqa.weadmin.com", target: '_blank' },
                    { text: '免费下载试用', to: '/Download' },
                ]
            },
            section2: {
                title: 'ITSM 功能介绍',
                desc: '高可配置性的方式100%的满足个性化和变化需求，比通过改“源代码”方式实现高一个维度',
                items: [
                    { 
                        title: '事件管理',
                        content: '管理支持工单的整个生命周期， 提高运维人员的效率，符合SLA。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '问题管理',
                        content: '分类、分析并解决问题，探究问 题出现的根本原因，有效避免类 似事件的重复发生。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '变更管理',
                        content: '借助自动化工作流，实现变更 计划、审批和实施的有效执行， 确保变更的成功。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '配置管理',
                        content: 'CMDB全面追踪和管理所有IT配 置项，映射配置项之间的关系及 依赖性。直观地分析变更和故障 可能产生的影响，从而制定明智 决策。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '服务级别管理',
                        content: '配置相应的自动化工作流及SLA， 提高用户的满意度以及IT服务的透 明性。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '知识库管理',
                        content: '积累无形资产不断积累常见问题的 解决方案，为用户提供更快捷的服 务与帮助。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '库存管理',
                        content: '集中管理所有IT资产，优化资产的 利用并保证资产的合格性。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '可用性管理',
                        content: '集中管理IT资源的可用性。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '发布管理',
                        content: '规划和控制软件以及相关硬件的成 功实施。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '数字内容管理',
                        content: '可灵活建设具有独特个性的网站，随时能编辑、修改网站内容和界面，并可将网页与权限、工作流绑定。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '服务目录管理',
                        content: '创建完整的IT和业务服务列表，让 用户可以对工作进行规划，对约定 的服务提供统一信息源。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    { 
                        title: '仪表盘',
                        content: '采用最先进的网页技术，灵活配置 可视化仪表盘，内含丰富的组件， 可满足各种数据以不同形式展现。',
                        linkTo: { label: '查看更多', to: '' }
                    },
                    
                ],
                tableTitle: '100%高可配置实施项目 VS 改源代码实施项目',
                columns: [
                    { title: "", dataIndex: 'type', render: text => <div className="table-text-bold">{text}</div> },
                    { title: "高可配置", dataIndex: 'config', className: 'table-text-config' },
                    { title: "改源代码", dataIndex: 'changCode' }
                ],
                dataSource: [
                    { type: '个性化需求', config: '自行配置', changCode: '需工程师修改代码' },
                    { type: '满足需求', config: '精准', changCode: '凑合使用、削足适履' },
                    { type: '长期维护', config: '自行配置', changCode: '无法升级' },
                    { type: '适应变化', config: '灵活适应', changCode: '需工程师按需新增代码' },
                    { type: '成本', config: '可控', changCode: '代价很高' },
                    { type: '项目交付', config: '实施工期短', changCode: '实施工期长' },
                    { type: '管理', config: '高效', changCode: '低效' },
                    { type: '扩展性', config: '灵活扩展新功能', changCode: '系统固化' },
                ],
                btns: [
                    { text: '在线演示', to: 'https://eccqa.weadmin.com', target: '_blank' }
                ]
            },
            section3: {
                img: '解决方案-Banner.jpg',
                title: '免费获取资料',
                content: { 
                    desc: '成熟解决方案为您的企业带来卓越IT服务管理',
                    content: '您的企业依靠IT来提供保持业务运行的关键服务。但是服务团队不断应对不断增长的需求和复杂性，这会增加成本，降低生产力，并使支持新业务目标变得更加困难。如果您的ITSM流程是手动的，非常容易出错的，并且对实际情况的了解也很少，那么它无济于事。面临的挑战是找到一种解决方案来驯服复杂性，消除昂贵的手动流程，保持用户的工作效率，同时提供运营所需的功能。'
                },
                tip: '',
                btn: {
                    text: '免费获取资料',
                    to: '/FreeSource'
                },
                config: {
                    type: 'horizontal',
                    imgAlign: 'left'
                }
            },
            section4: {
                title: '系统界面展示',
                tabs: [
                    { title: '服务目录', img: 'fwml.png' },
                    { title: '配置', img: 'pz.png' },
                    { title: '事件工单', img: 'sjgd.png' }
                ]
            },
            section5: {
                title: 'ITSM 价值体现',
                desc: '无需改源代码，百变金刚，适应管理流程的完善和变化',
                items: [
                    { icon: 'sv-plus-monitored', content: '通过配置实现“所想即所得”App' },
                    { icon: 'sv-xianshiqi', content: '化繁为简、降低成本，提升投资回报率' },
                    { icon: 'sv-fenjianguocheng_o', content: '一个平台满足不同阶段的业务需求' },
                    { icon: 'sv-guochengfenxi', content: '适应变化，只需配置即可快速更新系统' },
                    { icon: 'sv-good', content: '无需开发，只需配置' },
                    { icon: 'sv-jiangdichengben', content: '降低项目风险，缩短实施工期' },
                ],
                btns: [
                    { text: '获取解决方案', to: '/GetSolutions' }
                ]
            }
        },
        {
            title: '功能点介绍',
            section1: {
                img: '关于游龙-服务能力.jpg',
                title: '事件管理',
                desc: '快速恢复降低事故影响, ',
                desc2: '满足ITIL中突发事件管理的要求',
                content: '事件管理尽可能地快速的恢复IT资源的正常运行，避免业务中断，使事故对业务运营的影响降至最低，以保证IT资源的可用性水平与保持IT运维的最佳服务水平。通过流程引擎配置事件流转过程，自动流转无需人工干预。',
                btns: [
                    { text: '免费云试用版', to: "https://demo.siteview.com", target: '_blank' },
                    { text: '免费下载试用', to: '/Download' },
                ]
            },
            section2: {
                title: '优点',
                desc: '高效、用户体验佳、质量提升保障',
                items: [
                    { 
                        img: '解决方案-Banner.jpg',
                        title: '提高故障解决效率', 
                        tip: '',
                        content: '使用单一平台进行ITSM管理和协作工作时，可以加速服务恢复。',
                        config: {
                            type: 'vertical'
                        }
                    },
                    { 
                        img: '客户案例-Banner.jpg',
                        title: '提升用户体验', 
                        tip: '',
                        content: '通过直观的自助服务和透明的双向沟通，提 供好的消费者体验务。',
                        config: {
                            type: 'vertical'
                        }
                    },
                    { 
                        img: '解决方案-企业业务协同.jpg',
                        title: '不断改进服务质量', 
                        tip: '',
                        content: '通过基于角色的仪表板、性能指标和实时分 析，推动持续的服务改进。',
                        config: {
                            type: 'vertical',
                        }
                    },
                ],
                btns: [
                    { text: '在线演示', to: 'https://eccqa.weadmin.com', target: '_blank' }
                ]
            },
            section3: {
                title: '事件管理的最佳实践',
                desc: 'IT服务管理在优秀客户中的最佳实践，打造成熟事件管理功能',
                items: [
                    '关于游龙-Banner.jpg',
                    '关于游龙-Banner.jpg',
                    '关于游龙-Banner.jpg',
                    '关于游龙-Banner.jpg'
                ],
                btns: [
                    { text: '获取解决方案', to: '/GetSolutions' }
                ]
            }
        }
    ]
};

export default class ProductItsm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            active: 0,
        };
    }

    componentDidMount() {
        ScrollTo();
    }

    onTabChange = tab => {
        this.setState({tab}, () => {
            this.setState({active: tab});
        });
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center" style={{display: 'flex', justifyContent: 'center'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    render() {
        const { tab, active } = this.state;
        return (
            <div className="productView productItsm">
                <div className="product-section1">
                    <Row className="product-section1-header" type="flex" align="middle">
                        <Col md={24} lg={12}>
                            <span className="title">{data.title}</span>
                            <Link to="/ProductEcc" style={{marginLeft: '30px'}}>监测</Link>
                        </Col>
                        <Col md={24} lg={12}>
                            <div className="func-tab">
                                {data.tabs.map((t, index) => (
                                    <span key={index} className={tab === index ? 'active': ''} onClick={()=>this.onTabChange(index)}>{t.title}</span>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    {data.tabs.map((tt, index) => {
                        return (
                            <div className={"tab-pane-wrapper" + (active === index ? ' active': '')} key={index} style={{display: tab === index ? 'block': 'none'}}>
                                {index === 0 ? (
                                    <>
                                        <MainSectionDisplay
                                            {...tt.section1}
                                            titleStyle={{marginTop: '63px'}}
                                            descStyle={{marginTop: '20px'}}
                                        />
                                        <MainSectionDisplay
                                            title={tt.section2.title}
                                            desc={tt.section2.desc}
                                            titleStyle={{marginTop: '63px', fontSize: '30px'}}
                                            descStyle={{marginTop: '20px', fontSize: '18px'}}
                                        />
                                        <div className="product-itsm-section2">
                                            <Row className="f-block-wrapper" gutter={{sm: 24, md: 19}} type="flex" justify="center">
                                                {tt.section2.items.map((f, fIndex) => {
                                                    return (
                                                        <Col sm={24} md={8} lg={6} xxl={5} key={fIndex} style={{marginBottom: '19px'}}>
                                                            <div className="f-item">
                                                                <div className="f-item-container">
                                                                    <div className="f-item-title">{f.title}</div>
                                                                    <div className="f-item-content">{f.content}</div>
                                                                </div>
                                                                {/* <div className="f-item-link"><Link to={f.linkTo.to}>{f.linkTo.label}</Link></div> */}
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                            <div className="normal-section-title" style={{lineHeight: '80px', marginBottom: '16px'}}>{tt.section2.tableTitle}</div>
                                            <div className="normal-media-padding" style={{paddingBottom: '20px'}}>
                                                <Table rowKey="type" style={{background: '#fff'}} columns={tt.section2.columns} dataSource={tt.section2.dataSource} pagination={false} />
                                            </div>
                                            <div style={{textAlign: 'center', marginTop: '26px'}}>
                                                {tt.section2.btns.map((btn, bIndex) => {
                                                    return (
                                                        <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="product-itsm-section3">
                                            <ImgCard
                                                {...tt.section3}
                                                imgHeight="600px"
                                                content={
                                                    <div>
                                                        <div className="p-itsm-card-desc">{tt.section3.content.desc}</div>
                                                        <div className="p-itsm-card-content">{tt.section3.content.content}</div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="product-section3">
                                            <Row type="flex" justify="center">
                                                <Col md={24} lg={20} xl={18}>
                                                    <div className="normal-section-title">{tt.section4.title}</div>
                                                    <Tabs defaultActiveKey="0" renderTabBar={this.renderTabBar}>
                                                        {tt.section4.tabs.map((tab, sIndex) => (
                                                            <TabPane tab={tab.title} key={sIndex}>
                                                                <div className="tab-img-wrapper">
                                                                    <img className="tab-img" src={require(`../images/${tab.img}`)} alt=""/>
                                                                </div>
                                                            </TabPane>
                                                        ))}
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="product-itsm-section4">
                                            <MainSectionDisplay
                                                title={tt.section5.title}
                                                desc={tt.section5.desc}
                                                titleStyle={{marginTop: '63px', fontSize: '30px'}}
                                                descStyle={{marginTop: '20px', fontSize: '18px'}}
                                            />
                                            <Row className="se-block-wrapper" gutter={{sm: 0, md: 19}}>
                                                {tt.section5.items.map((se, seIndex) => (
                                                    <Col sm={24} md={8}  key={seIndex} style={{marginBottom: '19px'}}>
                                                        <div className="se-item">
                                                            <IconFont type={se.icon} />
                                                            <div className="se-item-content">{se.content}</div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <div style={{textAlign: 'center', marginTop: '26px'}}>
                                                {tt.section5.btns.map((btn, bIndex) => {
                                                    return (
                                                        <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                ): (
                                    <>
                                        <div className="product-itsm-section1">
                                            <div className="product-itsm-section1-img-wrapper" style={{backgroundImage: `url(${require('../images/' + tt.section1.img)})`}}></div>
                                            <div className="product-itsm-section1-content-wrapper">
                                                <div className="content-header">
                                                    <div className="content-header-title">{tt.section1.title}</div>
                                                    <div className="content-header-second-title">{tt.section1.desc}</div>
                                                    <div className="content-header-second-title">{tt.section1.desc2}</div>
                                                </div>
                                                <div className="content-content">{tt.section1.content}</div>
                                                <div className="content-btns">
                                                    {tt.section1.btns.map((item, index) => (
                                                        <WrapperButton className="linearBorderBtn" key={index} style={{width: '221px'}} btn={item}>{item.text}</WrapperButton>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-itsm-section2">
                                            <MainSectionDisplay
                                                title={tt.section2.title}
                                                desc={tt.section2.desc}
                                                titleStyle={{marginTop: '43px', fontSize: '30px'}}
                                                descStyle={{marginTop: '10px', fontSize: '18px'}}
                                            />
                                            <div className="card-container">
                                                <Row gutter={{sm: 0, md: 19}} type="flex" justify="center">
                                                    {tt.section2.items.map((item, index) => {
                                                        return (
                                                            <Col key={index} sm={24} md={12} lg={7} style={{marginBottom: '19px'}}>
                                                                <div className="card-item">
                                                                    <ImgCard {...item} imgHeight="310px" />
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                            <div style={{textAlign: 'center', marginTop: '26px'}}>
                                                {tt.section2.btns.map((btn, bIndex) => {
                                                    return (
                                                        <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="product-itsm-section3 product-itsm-section3-f">
                                            <MainSectionDisplay
                                                title={tt.section3.title}
                                                desc={tt.section3.desc}
                                                titleStyle={{marginTop: '43px', fontSize: '30px'}}
                                                descStyle={{marginTop: '10px', fontSize: '18px', marginBottom: '0px'}}
                                            />
                                            <div className="carousel-wrapper">
                                                <Carousel autoplay>
                                                    {tt.section3.items.map((item, index) => {
                                                        return (
                                                            <div key={index} className="carousel-item">
                                                                <img src={require(`../images/${item}`)} alt=""/>
                                                            </div>
                                                        )
                                                    })}
                                                </Carousel>
                                            </div>
                                            <div style={{textAlign: 'center', marginTop: '26px'}}>
                                                {tt.section3.btns.map((btn, bIndex) => {
                                                    return (
                                                        <WrapperButton key={bIndex} className="linearBtn" style={{width: '221px', margin: '0 15px'}} btn={btn}>{btn.text}</WrapperButton>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
