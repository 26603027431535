import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainSectionDisplay from '../components/common/MainSectionDisplay';
import { ScrollTo } from '../script/Utils';
import { Tabs, Input, Icon } from 'antd';

const { TabPane } = Tabs;

const data = {
    title: '常见问题与帮助中心',
    desc: '',
    extra: '找不到您想问的问题？',
    link: { label: '点击这里获取帮助', to: '' },
    tabs: [
        { title: '关于产品下载', content: `
            <div style="line-height: 60px; font-size: 16px; color: #0a0a0a;">
                <div style="font-size: 20px;">1. 什么电脑适配最新版ECC V2.0 Beta？</div>
                <div style="font-size: 20px;">2. 如何获取产品下载包？</div>
                <div style="font-size: 20px;">3. 怎么下载安装过程说明书？</div>
                <div style="font-size: 16px;">请进入<a style="text-decoration: underline">软件下载</a>页面，填入您需要的产品信息，我们的安装过程说明书将自动发到您的邮箱。</div>
            </div>
        ` },
        { title: '关于产品价格', content: '' },
        { title: '关于云试用版', content: '' },
        { title: '关于产品功能配置', content: '' }
    ]
};

export default class FAQ extends Component {

    componentDidMount() {
        ScrollTo();
    }

    renderTabBar = (props, DefaultTabBar) => {
        return (
            <div className="custom-tab-center" style={{display: 'flex', justifyContent: 'center'}}>
                <DefaultTabBar style={{width: '100%'}} {...props} />
            </div>
        )
    }

    render() {
        return (
            <div className="faqView">
                <div className="light-content" style={{paddingBottom: '45px'}}>
                    <MainSectionDisplay
                        title={data.title}
                        desc={<Input className="faq-search" placeholder="搜索" suffix={<Icon type="search" />} />}
                        titleStyle={{marginTop: '62px', textAlign: 'left', width: '85%', marginLeft: 'auto', marginRight: 'auto'}}
                        descStyle={{marginTop: '29px', marginBottom: '5px', textAlign: 'left', width: '85%'}}
                    />
                </div>
                <div className="verticalTab-wrapper">
                    <Tabs defaultActiveKey="0" tabPosition="left">
                        {data.tabs.map((item, index) => (
                            <TabPane tab={<span className="tab-title">{item.title}</span>} key={index}>
                                <div className="content-title"><span>{item.title}</span></div>
                                <div className="content-container" dangerouslySetInnerHTML={{__html: item.content ? item.content : ('content: ' + item.title)}}></div>
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
                <div className="bottom-extra">
                    <span>{data.extra}</span>
                    <span className="linearText"><Link to={data.link.to}>{data.link.label}</Link></span>
                </div>
            </div>
        )
    }
}
