import React, { Component } from 'react';
import BlockCard from './BlockCard';
import { Row, Col } from 'antd';

export default class GridDisplay extends Component {
    render() {
        const { items, gutter, spanRules, imgHeight, shadow, verticalGutter } = this.props;
        let _spanRules = {sm: 24, md: 12, lg: 8, xl: 6};
        if (spanRules) {
            _spanRules = Object.assign({}, spanRules);
        }
        return (
            <div style={{overflow: 'hidden'}}>
                <Row gutter={gutter || 19} type="flex" style={{padding: '10px'}}>
                    {items.map((item, index) => {
                        return (
                            <Col key={index} {..._spanRules} style={{marginBottom: verticalGutter ? verticalGutter : '45px'}}>
                                <div style={{background: '#fff', height: '100%'}}>
                                    <BlockCard {...item} imgHeight={imgHeight} shadow={shadow}/>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }
}
