import React, { Component } from "react";
import WrapperButton from "../components/common/WrapperButton";
import GlobalBreadcrumb from "../components/common/GlobalBreadcrumb";
import MainSectionDisplay from "../components/common/MainSectionDisplay";
import GlobalMarkdown from "../markdown/GlobalMarkdown";
import { ScrollTo } from "../script/Utils";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const data = {
  title: "用户指南步骤",
  img: 'carl-heyerdahl-KE0nC8-58MQ-unsplash.jpg',
  desc: "全面、深度监测，智能运维管理，保障企业运营最大化效益",
  btns: [{ text: "免费云试用版", to: "https://demo.siteview.com", target: '_blank' }, { text: "免费下载试用", to: "/Download" }],
  tabs: [
    { title: "快速上手", content: "快速上手" },
    { title: "Step 0: 下载、安装和部署", content: "下载、安装和部署" },
    { title: "Step 1: 系统登录", content: "登录、注销和查看" },
    { title: "Step 2: 整体界面说明", content: "整体界面说明" },
    { title: "Step 3: 添加组和编辑组", content: "添加组和编辑组" },
    { title: "Step 4: 添加资源", content: "添加资源" },
    { title: "Step 5: 添加监测器", content: "添加监测器" },
    { title: "Step 6: 添加报警", content: "添加报警" },
    { title: "Step 7: 添加报表", content: "添加报表" },
    { title: "Step 8: 立体拓扑图", content: "立体拓扑图" },
    { title: "Step 9: 状态巴图", content: "状态巴图" },
    { title: "Step 10: 矩阵树图", content: "矩阵树图" },
    { title: "Step 11: 微信小程序", content: "微信小程序" },
    { title: "Step 12: 用户角色管理", content: "用户角色管理" },
    { title: "Step 13: 资源监测设置", content: "资源监测设置" },
    { title: "Step 14: 数据管理", content: "数据管理" },
    { title: "Step 15: 系统运行时管理", content: "系统运行时管理" },
    { title: "Step 16: 系统检测", content: "系统检测" },
    { title: "Step 17: 操作审计", content: "操作审计" },
    { title: "Step 18: 软件许可", content: "软件许可" },
    { title: "Step 19: 资源图标库", content: "资源图标库" },
    { title: "Step 20: 设备信息维护", content: "设备信息维护" },
    {
      title: "高级 Step 1: 资源扫描发现",
      content: "资源扫描自动发现",
      path: "adv-01"
    },
    { title: "高级 Step 2: 数据库替换", content: "数据库替换", path: "adv-02" },
    {
      title: "高级 Step 3: ECC性能优化",
      content: "ECC性能优化详解",
      path: "adv-03"
    },
    {
      title: "高级 Step 4: ECC守护进程",
      content: "ECC守护进程介绍",
      path: "adv-04"
    },
    { title: "高级 Step 5: 系统自检", content: "系统自检", path: "adv-05" },
    {
      title: "高级 Step 6: 远程桌面",
      content: "Linux系统安装Docker操作说明-作用远程桌面连接功能",
      path: "adv-06"
    },
    {
      title: "监测器仪表板 Step 1: 整体说明",
      content: "监测器仪表板整体说明",
      path: "dashboard-01"
    },
    {
      title: "监测器仪表板 Step 2: 配置",
      content: "监测器仪表板配置",
      path: "dashboard-02"
    },
    {
      title: "监测器仪表板 Step 3: 部件配置",
      content: "监测器仪表板部件配置",
      path: "dashboard-03"
    },
    {
      title: "监测器仪表板 Step 4: 高级配置",
      content: "监测器仪表板高级配置",
      path: "dashboard-04"
    },
    { title: "MySQL数据库下载安装使用", content: "MySQL数据库下载安装使用" },
    { title: "集成三人即时通", content: "SITEVIEW ECC集成三人即时通会“聊天”" }
  ]
};

export default class QuickStart extends Component {
  componentDidMount() {
    ScrollTo();
  }

  renderTabBar = (props, DefaultTabBar) => {
    return (
      <div
        className="custom-tab-center"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DefaultTabBar style={{ width: "100%" }} {...props} />
      </div>
    );
  };

  render() {
    return (
      <div className="quickStartView">
        <div className="breadcrumb-wrapper">
          <GlobalBreadcrumb {...this.props} />
        </div>
        <div className="light-content" style={{ paddingBottom: "45px" }}>
          <MainSectionDisplay
            img={data.img}
            title={data.title}
            desc={data.desc}
            height="300px"
            titleStyle={{
              marginTop: "62px",
              textAlign: "left",
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            descStyle={{
              marginTop: "29px",
              marginBottom: "5px",
              textAlign: "left",
              width: "85%"
            }}
          />
        </div>
        <div className="verticalTab-wrapper">
          <Tabs defaultActiveKey="0" tabPosition="left">
            {data.tabs.map((item, index) => (
              <TabPane
                tab={<span className="tab-title">{item.title}</span>}
                key={index}
              >
                <div className="content-title">
                  <span>{item.content}</span>
                </div>
                <div className="content-container">
                  <GlobalMarkdown
                    markdown={require(`../markdown/QuickStart/${
                      item.path
                        ? item.path
                        : index === 0
                        ? "index"
                        : "step-" + (index - 1)
                    }/index.md`)}
                    imgPath={`./QuickStart/${
                      item.path
                        ? item.path
                        : index === 0
                        ? "index"
                        : "step-" + (index - 1)
                    }/`}
                  />
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
        <div style={{ textAlign: "center", marginTop: "45px" }}>
          {data.btns.map((btn, index) => {
            return (
              <WrapperButton
                key={index}
                className="linearBtn"
                style={{ width: "221px", margin: "0 15px" }}
                btn={btn}
              >
                {btn.text}
              </WrapperButton>
            );
          })}
        </div>
      </div>
    );
  }
}
