import React, { Component } from "react";
// import IconFont from "../components/common/IconFont";
import GlobalBreadcrumb from "../components/common/GlobalBreadcrumb";
import MainSectionDisplay from "../components/common/MainSectionDisplay";
import { ScrollTo } from "../script/Utils";
import { 
    // Button,
    // Icon,
    // Menu,
    // Dropdown,
    Table
} from "antd";

const data = {
  title: "诚聘英才",
  desc: "加入游龙科技，成就你职业的高峰",
  data: [
    {
      key: 1,
      postName: "LinuxC/C++开发工程师",
      postDesc: ["负责基于Linux的企业网关和服务器相关产品开发"],
      postRequire: {
        desc: "",
        requires: [
          "1、扎实的C/C++基础知识，3年以上的linux 网络程序开发经验；",
          "2、熟练使用Linux环境，熟悉Debian或者CentOS　Linux；",
          "3、有 OpenWRT 开发经验，熟悉 Broadcom 路由器相关芯片者优先；",
          "4、熟悉TCP/IP协议，了解路由及交换原理；"
        ],
        welfare: ["提供有竞争力的薪资和奖金方案"],
        address: "上班地址：长沙市天心区侯家塘凯华大厦12楼",
        email: "简历投递邮箱：HR@dragonflow.com"
      },
      address: "长沙市天心区侯家塘凯华大厦12楼",
      counts: 2,
      date: "2019-07-05"
    },
    {
      key: 2,
      postName: "嵌入式开发工程师（路由器）",
      postDesc: [
        "1、负责根据产品需求进行嵌入式软件的需求分析及架构、模块设计；",
        "2、负责嵌入式软件设计、开发、测试、维护和优化等工作，快速定位并修复软件缺陷；",
        "3、负责嵌入式软件在不同产品平台的移植；"
      ],
      postRequire: {
        desc: "WiFi路由器方向：",
        requires: [
          "1、熟悉OPENWRT系统的开发，熟悉路由器软件架构，并从事过相关路由器软件开发工作；",
          "2、熟悉以太网相关业务TCP/IP、IPV4/IPV6、IGMP、DHCP、PPPOE、TR069、OMCI等；",
          "3、有无线路由器开发经验，熟悉无线路由器的各项性能参数，了解无线性能的测试方案；",
          "4、有WiFi开发调试经验，熟悉802.11a/b/g/n/ac 相关协议，熟悉MTK，realtek等无线芯片驱动；",
          "5、有WiFi射频产品软件调试开发的经验；"
        ],
        welfare: ["提供有竞争力的薪资和奖金方案"],
        address: "上班地址：长沙市天心区侯家塘凯华大厦12楼",
        email: "简历投递邮箱：HR@dragonflow.com"
      },
      address: "长沙市天心区侯家塘凯华大厦12楼",
      counts: 2,
      date: "2019-07-05"
    },
    {
      key: 3,
      postName: "C/C++开发工程师",
      postDesc: ["负责使用C/C++技术开发物联网相关应用"],
      postRequire: {
        desc: "",
        requires: [
          "1、扎实的C/C++基础知识；",
          "2、一年以上嵌入式linux 网络程序开发经验，熟悉 openwrt & dd-wrt 者优先；",
          "3、精通计算机网络架构以及常用网络协议，了解路由及交换原理；",
          "4、有单片机开发经验者优先；"
        ],
        welfare: ["提供有竞争力的薪资和奖金方案"],
        address: "上班地址：长沙市天心区侯家塘凯华大厦12楼",
        email: "简历投递邮箱：HR@dragonflow.com"
      },
      address: "长沙市天心区侯家塘凯华大厦12楼",
      counts: 2,
      date: "2019-07-05"
    }
  ]
};
export default class Advertises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownValue1: "按部门",
      dropdownValue2: "按地点",
      expandedRowKeys: []
    };
  }

  componentDidMount() {
    ScrollTo();
  }

  renderTabBar = (props, DefaultTabBar) => {
    return (
      <div
        className="custom-tab-center"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DefaultTabBar style={{ width: "100%" }} {...props} />
      </div>
    );
  };

  handleMenuClick = (key, value) => {
    this.setState({ [key]: value });
  };

  onDetailClick = key => {
    let index = this.state.expandedRowKeys.indexOf(key);
    if (index > -1) {
      this.setState({ expandedRowKeys: [] });
    } else {
      this.setState({ expandedRowKeys: [key] });
    }
  };

  render() {
    // const { dropdownValue1, dropdownValue2 } = this.state;
    // const menu = (
    //   <Menu onClick={e => this.handleMenuClick("dropdownValue1", e.key)}>
    //     <Menu.Item key="按部门">按部门</Menu.Item>
    //     <Menu.Item key="按其他">按其他</Menu.Item>
    //   </Menu>
    // );
    // const menu2 = (
    //   <Menu onClick={e => this.handleMenuClick("dropdownValue2", e.key)}>
    //     <Menu.Item key="按地点">按地点</Menu.Item>
    //     <Menu.Item key="按其他">按其他</Menu.Item>
    //   </Menu>
    // );
    const columns = [
      { title: "岗位名称", dataIndex: "postName" },
      {
        title: "岗位描述",
        dataIndex: "postDesc",
        width: "30%",
        render: (text, record) => (
          <div style={{ lineHeight: "32px" }}>
            {record.postDesc.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        )
      },
      {
        title: "岗位要求",
        dataIndex: "postRequire",
        render: (text, record) => (
          <a
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => this.onDetailClick(record.key)}
          >
            查看详情
          </a>
        )
      },
      { title: "工作地点", dataIndex: "address" },
      { title: "招聘人数", dataIndex: "counts" },
      { title: "发布时间", dataIndex: "date" }
    ];
    return (
      <div className="aboutView">
        <div className="breadcrumb-wrapper">
          <GlobalBreadcrumb {...this.props} />
        </div>
        <div className="light-content" style={{ paddingBottom: "45px" }}>
          <MainSectionDisplay
            title={data.title}
            desc={data.desc}
            titleStyle={{
              marginTop: "62px",
              textAlign: "left",
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            descStyle={{
              marginTop: "29px",
              marginBottom: "5px",
              textAlign: "left",
              width: "85%"
            }}
          />
        </div>
        {/* <div className="filter-bar">
                    <div>
                        <span className="title">筛选列表</span>
                        <Dropdown overlay={menu}>
                            <Button>{dropdownValue1} <IconFont type="sv-LC_icon_down_fill" /></Button>
                        </Dropdown>
                        <Dropdown overlay={menu2}>
                            <Button>{dropdownValue2} <IconFont type="sv-LC_icon_down_fill" /></Button>
                        </Dropdown>
                    </div>
                    <Button>搜索 <Icon type="search" /></Button>
                </div> */}
        <div className="filter-list-wrapper light-content">
          <div style={{ padding: "0 25px" }}>
            <Table
              className="hidden-expand-row"
              rowKey="key"
              columns={columns}
              dataSource={data.data}
              pagination={false}
              expandedRowKeys={this.state.expandedRowKeys}
              expandedRowRender={record => (
                <div style={{ lineHeight: "32px" }}>
                  {record.postRequire.desc ? (
                    <div>{record.postRequire.desc}</div>
                  ) : null}
                  {record.postRequire.requires.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                  {record.postRequire.welfare.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                  {record.postRequire.address ? (
                    <div>{record.postRequire.address}</div>
                  ) : null}
                  {record.postRequire.email ? (
                    <div>{record.postRequire.email}</div>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
