import React, { Component } from 'react';
import WrapperButton from './WrapperButton';

export default class ImgCard extends Component {
    render() {
        const { img, title, tip, content, btn, config, imgHeight } = this.props;
        const btns = btn ? (btn instanceof Array ? btn : [btn]) : [];
        return (
            <div className={"imgCard" + ((config && (" " + config.type)) || "")}>
                <div className="cardWrapper">
                    <div className="img-wrapper" style={{height: imgHeight || (config && config.type === 'horizontal' ? '340px' : '430px')}}><img src={require(`../../images/${img}`)} alt=""/></div>
                    <div className="content-wrapper">
                        <div>
                            <div className="title">{title}<span className="tip">{tip}</span></div>
                            <div className="content">{content}</div>
                        </div>
                        <div>
                            {btns.map((btn, index) => (
                                <WrapperButton key={index} className="linearBorderBtn" style={{width: '221px', marginLeft: index === 0?'0px': '20px'}} btn={btn}>{btn.text}</WrapperButton>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
