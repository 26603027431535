import React, { Component } from "react";
import IconFont from "../components/common/IconFont";
import BlockCard from "../components/common/BlockCard";
import GlobalBreadcrumb from "../components/common/GlobalBreadcrumb";
import GlobalMarkdown from "../markdown/GlobalMarkdown";
import { ScrollTo } from "../script/Utils";
import { NewsData } from "../script/MockDatas";
import { Row, Col, Carousel, notification, Button } from "antd";

const data = {
  items: NewsData
};

export default class NewsSubView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      others: []
    };
  }

  componentDidMount() {
    ScrollTo();
    let item = data.items.find(
      d => d.link.to.pathname === this.props.location.pathname
    );
    this.setState({ others: data.items.filter(d => d.type === item.type) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      ScrollTo();
      let item = data.items.find(
        d => d.link.to.pathname === this.props.location.pathname
      );
      this.setState({ others: data.items.filter(d => d.type === item.type) });
    }
  }

  getItemIndex = () => {
    return data.items.findIndex(
      d => d.link.to.pathname === this.props.location.pathname
    );
  };

  next = () => {
    let index = this.getItemIndex();
    if (index < data.items.length - 1) {
      this.props.history.push(data.items[index + 1].link.to.pathname);
    } else {
      notification.info({
        message: "提示",
        description: "已经是最后一个了"
      });
    }
  };

  prev = () => {
    let index = this.getItemIndex();
    if (index > 0) {
      this.props.history.push(data.items[index - 1].link.to.pathname);
    } else {
      notification.info({
        message: "提示",
        description: "已经是第一个了"
      });
    }
  };

  render() {
    const itemIndex = this.getItemIndex();
    const currentItem = data.items[itemIndex];
    const prevTitle = itemIndex === 0 ? "" : data.items[itemIndex - 1].title;
    const nextTitle =
      itemIndex === data.items.length - 1
        ? ""
        : data.items[itemIndex + 1].title;
    let carouselItems = [];
    for (let i = 0; i < this.state.others.length; i += 3) {
      carouselItems.push(this.state.others.slice(i, i + 3));
    }
    return (
      <div className="newsSubView customerCaseSubView">
        <div className="breadcrumb-wrapper">
          <GlobalBreadcrumb {...this.props} />
        </div>
        <div className="normal-media-padding news-content">
          {data.items.length ? (
            <div className="pv-btn-wrapper">
              <div>
                <Button
                  className="btn-prev"
                  type="link"
                  onClick={this.prev}
                  disabled={itemIndex === 0}
                >
                  <IconFont type="sv-uparrow" />
                  上一篇
                </Button>
                <div className="pn-desc">{prevTitle}</div>
              </div>
              <div>
                <Button
                  className="btn-next"
                  type="link"
                  onClick={this.next}
                  disabled={itemIndex === data.items.length - 1}
                >
                  下一篇
                  <IconFont type="sv-uparrow-copy" />
                </Button>
                <div className="pn-desc">{nextTitle}</div>
              </div>
            </div>
          ) : null}
          {currentItem ? (
            <div>
              {/* <div className="news-sub-intro">{currentItem.intro}</div> */}
              <div className="news-sub-title">{currentItem.title}</div>
              <div className="news-sub-date">{currentItem.intro}&nbsp;&nbsp;&nbsp;&nbsp;{currentItem.date}</div>
              <div className="news-sub-summary">
                <GlobalMarkdown
                  markdown={require(`../markdown/News/${
                    currentItem.path
                  }/index.md`)}
                  imgPath={`./News/${currentItem.path}/`}
                />
              </div>
            </div>
          ) : (
            <div>没找到对应新闻信息，请重试！</div>
          )}
          {data.items.length ? (
            <div className="pv-btn-wrapper">
              <div>
                <Button
                  className="btn-prev"
                  type="link"
                  onClick={this.prev}
                  disabled={itemIndex === 0}
                >
                  <IconFont type="sv-uparrow" />
                  上一篇
                </Button>
                <div className="pn-desc">{prevTitle}</div>
              </div>
              <div>
                <Button
                  className="btn-next"
                  type="link"
                  onClick={this.next}
                  disabled={itemIndex === data.items.length - 1}
                >
                  下一篇
                  <IconFont type="sv-uparrow-copy" />
                </Button>
                <div className="pn-desc">{nextTitle}</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="news-other-content">
          <div
            className="normal-section-title"
            style={{ textAlign: "left", padding: "0 6.2%" }}
          >
            其他{currentItem.type}新闻
          </div>
          <div className="carousel-wrapper">
            <Carousel autoplay>
              {carouselItems.map((item, index) => {
                return (
                  <div key={index} className="carousel-item">
                    <Row
                      gutter={19}
                      type="flex"
                      justify="center"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      {item.map((p, iIndex) => (
                        <Col key={iIndex} span={7}>
                          <div style={{ background: "#fff", height: "100%" }}>
                            <BlockCard {...p} imgHeight="276px" />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
