import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

const footerData = {
    logo: 'logo.png',
    left: [
        '全方位现代化IT服务管理平台，选用合适的SITEVIEW ITOSS<br/>解决方案将满足您今天和明天的需求。',
        '邮箱：contact@dragonflow.com<br/>联系地址：北京市海淀区丹棱街18号创富大厦19层'
    ],
    right: [
        { 
            title: '解决方案', 
            items: [
                { text: '企业运维', to: '/Solutions/企业运维' },
                { text: 'IT运营商', to: '/Solutions/IT运营商' },
                { text: '企业业务协同', to: '/Solutions/企业业务协同' },
                { text: '现场服务', to: '/Solutions/现场服务' },
                { text: '客户服务', to: '/Solutions/客户服务' },
                { text: '连锁IT服务', to: '/Solutions/连锁IT服务' },
                { text: '数据中心运维', to: '/Solutions/数据中心运维' },
                { text: '一体化运维', to: '/Solutions/一体化运维' },
            ]
        },
        // {
        //     title: '帮助与支持',
        //     items: [
        //         { text: '快速上手', to: '/QuickStart' },
        //         { text: '技术白皮书', to: '' },
        //         { text: '常见问题', to: '/FAQ' },
        //     ]
        // },
        {
            title: '关于游龙',
            items: [
                { text: '公司概况', to: '/About' },
                { text: '诚聘英才', to: '/About/Advertises' },
                { text: '新闻中心', to: '/About/NewsCenter' },
            ]
        }
    ],
    copyright: '©2021 北京游龙网网络科技有限公司 版权所有',
    recordInfo: '京ICP备14004049-1号'
}

export default class GlobalFooter extends Component {
    render() {
        return (
            <div className="app-footer">
                <Row gutter={{md: 0, lg: 8}}>
                    <Col md={24} lg={12}>
                        <div className="logo" style={{backgroundImage: `url(${require('../../images/logo.png')})`}}></div>
                        {footerData.left.map((item, index) => (
                            <div key={index} className="left-item" dangerouslySetInnerHTML={{__html: item}}></div>
                        ))}
                    </Col>
                    <Col md={24} lg={12}>
                        <div className="right-content">
                            {footerData.right.map((item, index) => {
                                let subItems = [];
                                for (let i = 0; i < item.items.length; i+=4) {
                                    subItems.push(item.items.slice(i, i+4));
                                }
                                return (
                                    <div key={index} className="right-item">
                                        <div className="right-item-title">{item.title}</div>
                                        <div>
                                            {subItems.map((subItem, sIndex) => {
                                                return (
                                                    <div key={sIndex} className="right-subitem">
                                                        {subItem.map((sItem, itemIndex) => (
                                                            <div key={itemIndex} className="right-item-item"><Link to={sItem.to}>{sItem.text}</Link></div>
                                                        )) }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
                <div className="copyright">{footerData.copyright} | 备案号：<a target="_blank" href="http://beian.miit.gov.cn">{footerData.recordInfo}</a></div>
            </div>
        )
    }
}
