import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightEighties } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactMarkdown from "react-markdown/with-html";

class GlobalMarkdown extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        markdown: "",
        imgPath: "./"
      };
  }

  componentWillMount() {
    fetch(this.props.markdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text, imgPath: this.props.imgPath || './' }));
  }

  componentWillReceiveProps(nextProps) {
    const { markdown, imgPath } = nextProps;
    if (markdown && markdown !== this.props.markdown) {
      fetch(markdown)
        .then(res => res.text())
        .then(text => this.setState({ markdown: text, imgPath: imgPath || './' }));
    }
  }

  render() {
    const { markdown, imgPath } = this.state;
    return (
      <ReactMarkdown
        className="markdown-body"
        source={markdown}
        escapeHtml={false}
        linkTarget="_blank"
        transformImageUri={url => require(`${(imgPath || './') + url}`)}
        renderers={{
          code: CodeBlock
        }}
      />
    );
  }
}

export default GlobalMarkdown;

class CodeBlock extends React.PureComponent {
  render() {
    const { value } = this.props;
    
    return (
      <SyntaxHighlighter language="" style={tomorrowNightEighties}>
        {value}
      </SyntaxHighlighter>
    );
  }
}
